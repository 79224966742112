<template lang="pug">
v-data-table(
  :headers="headers",
  :items="payouts",
  hide-default-footer,
  mobile-breakpoint="0"
)
  template(v-slot:item.created="{ item }")
    span {{ item.created | date }}
  template(v-slot:item.period="{ item }")
    span {{ item.begin | date }} - {{ item.end | date }}
  template(v-slot:item.deduct="{ item }")
    span {{ item.deduct | currency }}
  template(v-slot:item.amount="{ item }")
    span {{ item.amount | currency }}
</template>

<script>
export default {
  props: ["payouts"],
  data() {
    return {
      headers: [
        { text: "Created", value: "created", sortable: false },
        { text: "Period", value: "period", sortable: false },
        { text: "#Selflane", value: "count", sortable: false },
        { text: "#Other", value: "countNS", sortable: false },
        { text: "Deduct", value: "deduct", sortable: false },
        { text: "Transfer Amount", value: "amount", sortable: false },
      ],
    };
  },
};
</script>
