<template lang="pug">
.d-flex.flex-row.align-center
  //- v-btn(@click='loadWithNoPayout()' outlined).text-capitalize.mr-3 Load No Payouts
  MonthSelector(@select='select')
</template>

<script>
import MonthSelector from "@/components/basic/MonthSelector";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  components: { MonthSelector },
  data() {
    return {
      range: null,
    };
  },
  computed: {
    ...mapGetters(["deliv"]),
  },
  methods: {
    ...mapActions(["setDriverPayouts"]),
    select(data) {
      if (!this.deliv) return;
      if (data) this.range = data.range;
      if (!this.range) return;
      const options = {
        criteria: {
          deliv: this.deliv._id,
          created: { $gte: this.range.begin, $lt: this.range.end },
        },
      };
      this.axios.post("/driver/payouts/list", options).then(response => {
        this.setDriverPayouts(response.data);
      });
    },
    loadWithNoPayout() {
      if (!this.range) return;
      const options = {
        criteria: {
          created: { $gte: this.range.begin, $lt: this.range.end },
          type: { $ne: 1 },
          status: { $gte: 0 },
          driverPayout: { $exists: false },
        },
        select: "_id",
      };
      this.axios.post("/delivOrders/list", options).then(response => {
        const ids = _.pluck(response.data, "_id");
        if (!ids || !ids.length) {
          alert("All have payouts");
          return;
        }
        EventBus.$emit("show-delivorders", ids);
      });
    },
  },
};
</script>
