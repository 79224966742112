<template lang="pug">
div(v-if="customer")
  div {{ customer.name }} {{ customer.phone | phone }}
  div(v-if="customer.address")
    .d-flex.flex-row.align-center 
      span {{ customer.address | addressStreetCity }}
      v-spacer
      v-icon(v-if="hasNote", @click="showNote = !showNote") mdi-chevron-down
    .wrap-note.caption(v-if="showNote") {{ customer.address.note }}
</template>

<script>
export default {
  props: ["delivOrder"],
  data() {
    return { showNote: false };
  },
  computed: {
    customer() {
      if (
        this.delivOrder &&
        this.delivOrder.order &&
        this.delivOrder.order.customer
      )
        return this.delivOrder.order.customer;
      else return this.delivOrder.customer;
    },
    hasNote() {
      return (
        this.customer &&
        this.customer.address &&
        this.customer.address.note &&
        this.customer.address.note.length
      );
    },
  },
};
</script>

<style scoped>
.wrap-note {
  word-wrap: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
}
</style>
