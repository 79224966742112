<template lang="pug">
div
  CreateClient(@done="load")
  Summary(:bizs="bizs")
  List(:bizs="bizs", @remove="remove")
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Summary from "./../Share/Summary";
import List from "./List";
import CreateClient from "./CreateClient";

export default {
  components: { Summary, List, CreateClient },
  data() {
    return {
      bizs: [],
    };
  },
  computed: {
    ...mapGetters(["deliv"]),
  },
  methods: {
    async load() {
      const params = { delivId: this.deliv._id };
      const res = await this.axios.post("/delivbiz/listBizsByDeliv", params);
      this.bizs = res.data;
    },
    async remove(bizId) {
      if (!this.deliv || !bizId) return;
      const params = { criteria: { deliv: this.deliv._id, biz: bizId } };
      await this.axios.post("/delivbiz/remove", params);
      this.load();
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-deliv", this.load);
  },
  destroyed() {
    EventBus.$off("switch-deliv");
  },
};
</script>
