<template lang="pug">
div
  v-list.py-0(dense)
    v-list-item(v-for="(item, index) in stripePersons", :key="index")
      v-list-item-content
        v-list-item-title {{ item.first_name }} {{ item.last_name }}
        v-list-item-subtitle.text-capitalize {{ item.verification.status }}
      v-list-item-action
        DeletePerson(:person="item", v-if="stripePersons.length > 1")
  .ma-3.text-center.subtitle-2(v-if="!stripePersons || !stripePersons.length") Please Add a Company Owner
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import DeletePerson from "./DeletePerson";

export default {
  components: { DeletePerson },
  computed: {
    ...mapGetters(["stripeAccount", "stripePersons"]),
  },
  methods: {
    ...mapActions(["setStripePersons"]),
    load() {
      if (!this.stripeAccount) return;
      const options = { accountId: this.stripeAccount.id };
      this.axios.post("/stripe/persons/list", options).then(response => {
        this.setStripePersons(response.data);
      });
    },
  },
  mounted() {
    this.setStripePersons([]);
    this.load();
    EventBus.$on("reload-stripe-persons", this.load);
  },
  destroyed() {
    EventBus.$off("reload-stripe-persons");
  },
};
</script>
