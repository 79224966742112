<template lang="pug">
div(v-if="driverSchedules")
  .d-flex.flex-row.justify-end.align-center
    v-btn(icon, @click="previous()")
      v-icon mdi-chevron-left
    v-btn(icon, @click="next()")
      v-icon mdi-chevron-right
  v-simple-table.elevation-1(dense)
    thead
      tr
        th Time
        th.text-center(v-for="(item, dindex) in dates", :key="dindex") {{ item | datetime('ddd M/D') }}
    tbody
      tr(v-for="(item, tindex) in timeBlocks", :key="tindex")
        td.font-weight-medium {{ item.time | minutesToTime }}
        td.text-center(
          v-for="(kitem, kindex) in item.items",
          :key="kindex",
          :class="{ secondary: kitem.count > 0 }",
          @click="show(kitem.users)",
          role="button"
        )
          span.grey--text.text--lighten-1(v-if="kitem.count") {{ kitem.count }}
</template>

<script>
import moment from "moment";
import _ from "underscore";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      startStep: 0,
    };
  },
  computed: {
    ...mapGetters(["driverSchedules", "drivers"]),
    dates() {
      const range = _.range(0, 7);
      const result = _.map(range, (o) => {
        const step = this.startStep + o;
        return moment().add(step, "d").format("YYYY-MM-DD");
      });
      return result;
    },
    timeBlocks() {
      const range = _.range(480, 1320, 30);
      return _.map(range, (mm) => {
        const items = _.map(this.dates, (date) => {
          let count = 0;
          const qualified = _.filter(this.driverSchedules, (schedule) => {
            if (schedule.date != date || !schedule.status) return false;
            return _.find(schedule.blocks, (block) => {
              return block.mm == mm && block.status;
            });
          });
          if (qualified) count = qualified.length;
          const users = _.pluck(qualified, "user");
          return {
            date: date,
            count: count,
            users: users,
          };
        });
        return {
          time: mm,
          items: items,
        };
      });
    },
  },
  methods: {
    ...mapActions(["setDriverSchedules"]),
    previous() {
      this.startStep -= 7;
      if (this.startStep < 0) this.startStep = 0;
      this.load();
    },
    next() {
      this.startStep += 7;
      this.load();
    },
    show(users) {
      EventBus.$emit("show-driver-schedule-block", users);
    },
    async load() {
      const userIds = _.pluck(this.drivers, "user");
      const params = {
        criteria: {
          user: { $in: userIds },
          date: { $in: this.dates },
        },
      };
      const res = await this.axios.post("/driver/schedules/list", params);
      this.setDriverSchedules(res.data);
    },
  },
  mounted() {
    this.load();
  },
};
</script>
