import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":""}},[_vm._l((_vm.items),function(item,index){return [(index != 0)?_c(VDivider):_vm._e(),_c(VListItem,{on:{"click":function($event){return _vm.select(item)}}},[_c(VListItemContent,[(item.company)?_c('span',[_vm._v(_vm._s(item.company.name))]):_vm._e()]),(item.deliv == _vm.deliv._id)?_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")])],1):_vm._e()],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }