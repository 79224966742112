<template lang="pug">
v-container
  .d-flex.flex-row.align-center.justify-space-between
    .sl-title Finances
    div
      v-row
        v-btn.text-capitalize.mx-3(
          v-for="(item, index) in items",
          :key="index",
          small,
          active-class="accent",
          :active="item.url === $route.path",
          :to="item.url",
          rounded,
          depressed
        ) {{ item.title }}
  v-divider.mb-5
  router-view
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Payments", url: "/finances/payments" },
        { title: "Tax", url: "/finances/tax" },
      ],
    };
  },
};
</script>
