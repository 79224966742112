<template lang="pug">
v-data-table(
  :headers="headers",
  :items="persons",
  sort-by="email",
  hide-default-footer
)
  template(v-slot:item.phone="{ item }")
    span {{ item.phone | phone }}
  template(v-slot:item.actions="{ item }")
    v-row
      Edit.mr-3(:person="item")
      Delete(:person="item", v-if="persons && persons.length > 1")
      PopulateUser(:person="item")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Edit from "./Edit";
import Delete from "./Delete";
import PopulateUser from "./PopulateUser";

export default {
  components: { Edit, Delete, PopulateUser },
  data() {
    return {
      headers: [
        { text: "User", value: "email" },
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "Role", value: "role" },
        { text: "", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters(["deliv", "persons"]),
  },
  methods: {
    ...mapActions(["setPersons"]),
    async load() {
      if (!this.deliv) return;
      const params = { criteria: { deliv: this.deliv._id } };
      const res = await this.axios.post("/persons/list", params);
      this.setPersons(res.data);
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-deliv", this.load);
  },
  destroyed() {
    EventBus.$off("switch-deliv");
  },
};
</script>
