<template lang="pug">
div
  Table
  HireDriver
  RejectDriver
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Table from "./Table";
import HireDriver from "./HireDriver";
import RejectDriver from "./RejectDriver";

export default {
  components: { Table, HireDriver, RejectDriver },
  computed: {
    ...mapGetters(["deliv"]),
  },
  methods: {
    ...mapActions(["setDrivers"]),
    async load() {
      if (!this.deliv || !this.deliv._id) return;
      const zones = this.deliv.zones;
      const params = {
        criteria: { deliv: null, zones: { $in: zones }, stage: 0 },
      };
      const res = await this.axios.post("/driver/accounts/list", params);
      this.setDrivers(res.data);
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-deliv", this.load);
  },
  destroyed() {
    EventBus.$off("switch-deliv");
  },
};
</script>
