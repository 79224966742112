import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSimpleTable,{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Created")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Period")]),_c('th',[_vm._v("# Orders")]),_c('th',[_vm._v("Base")]),_c('th',[_vm._v("Tip")]),_c('th',[_vm._v("Deduct")]),_c('th',[_vm._v("Total")]),_c('th')])]),_c('tbody',[_vm._l((_vm.driverPayouts),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm._f("datetime")(item.created,'M/D')))]),_c('td',[(item.name)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm._f("datetime")(item.begin,'M/D'))+" - "+_vm._s(_vm._f("datetime")(item.end,'M/D')))]),_c('td',[_vm._v(_vm._s(item.count))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.fee)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.tip)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(-item.deduct)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.amount)))]),_c('td',[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","small":""}},on),[_c(VIcon,{staticClass:"fa fa-ellipsis-h",attrs:{"small":"","color":"secondary"}})],1)]}}],null,true)},[_c(VList,{attrs:{"dense":""}},[_c('ShowOrders',{attrs:{"payout":item}})],1)],1)],1)])}),_c('tr',[_c('td',[_vm._v("Summary")]),_c('td'),_c('td',[_vm._v(_vm._s(_vm.driverPayouts.length))]),_c('td',[_vm._v(_vm._s(_vm.sum.count))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(_vm.sum.fee)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(_vm.sum.tip)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(-_vm.sum.deduct)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(_vm.sum.amount)))])])],2),_c('DelivOrders')],1)}
var staticRenderFns = []

export { render, staticRenderFns }