<template lang="pug">
v-simple-table(dense)
  thead
    tr
      th Driver
      th #
      th.text-right Total
      th.text-right Average
  tbody
    tr(v-for="(item, index) in drivers", :key="index")
      td {{ item.name }}
      td {{ item.count }}
      td.text-right {{ item.total | currency }}
      td.text-right {{ item.average | currency }}
</template>

<script>
import _ from "underscore";

export default {
  props: ["list"],
  computed: {
    drivers() {
      if (!this.list) return [];
      const data = _.chain(this.list)
        .reject((o) => !o.driver || !o.driver.id)
        .groupBy((o) => o.driver.id)
        .map((o, k) => {
          const fee = _.reduce(
            o,
            (memo, item) => {
              return memo + item.fee;
            },
            0
          );
          const tip = _.reduce(
            o,
            (memo, item) => {
              return memo + item.tip;
            },
            0
          );
          return {
            id: k,
            name: o[0].driver.name,
            fee: fee,
            tip: tip,
            total: fee + tip,
            count: o.length,
            average: (fee + tip) / o.length,
          };
        })
        .sortBy("name")
        .value();
      return data;
    },
  },
};
</script>
