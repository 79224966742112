import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.persons,"sort-by":"email","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("phone")(item.phone)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c('Edit',{staticClass:"mr-3",attrs:{"person":item}}),(_vm.persons && _vm.persons.length > 1)?_c('Delete',{attrs:{"person":item}}):_vm._e(),_c('PopulateUser',{attrs:{"person":item}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }