<template lang="pug">
div
  v-row(wrap, dense, v-for="(group, index) in groups", :key="index")
    v-col(cols="12", sm="6", md="4", v-for="item in group", :key="item._id")
      Cell(:delivOrder="item") 
</template>

<script>
import Cell from "./Cell";
import _ from "underscore";

export default {
  props: ["items"],
  computed: {
    groups() {
      return _.chain(this.items)
        .groupBy((o) => o.type)
        .sortBy((o, k) => {
          return k;
        })
        .value();
    },
  },
  components: { Cell },
};
</script>
