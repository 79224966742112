<template lang="pug">
.pa-3
  .sl-title Business Profile
  v-divider.mb-3
  Basic
  Address
</template>

<script>
import Basic from "./Basic/index";
import Address from "./Address/index";

export default {
  components: {
    Basic,
    Address,
  },
};
</script>
