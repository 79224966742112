import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row align-center subtitle-2"},[(_vm.isSelflane)?_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$selflane_rev")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("time")(_vm.delivOrder.needed)))]),(_vm.late)?_c('span',{staticClass:"ml-2 error--text"},[_vm._v("Late!!!")]):_vm._e(),(_vm.hasAlcohol)?_c(VChip,{staticClass:"ml-2",attrs:{"small":"","dark":"","color":"secondary"}},[_vm._v("Alcohol")]):_vm._e(),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("mdi-dots-horizontal")])],1)]}}])},[_c(VList,{staticClass:"py-0",attrs:{"dense":""}},[(_vm.isSelflane)?_c(VListItem,{on:{"click":function($event){return _vm.show()}}},[_vm._v("Order Details")]):_c(VListItem,{staticClass:"red--text",on:{"click":function($event){return _vm.handleCancel()}}},[_vm._v("Cancel")]),(_vm.canTransfer)?_c(VListItem,{on:{"click":function($event){return _vm.handleTransfer()}}},[_vm._v("Transfer Driver")]):_vm._e(),(_vm.canRedeliver)?_c(VListItem,{on:{"click":function($event){return _vm.handleRedeliver()}}},[_vm._v("Redeliver")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }