<template lang="pug">
v-dialog(max-width="500")
  template(v-slot:activator="{ on }")
    v-img(:src="deliv_photo", v-if="deliv_photo", contain, v-on="on")
  v-card(v-if="deliv_photo")
    v-img(:src="deliv_photo", v-if="deliv_photo", contain)
</template>

<script>
export default {
  props: ["delivOrder"],
  computed: {
    deliv_photo() {
      if (this.delivOrder && this.delivOrder.deliv_photo)
        return "https://media.selflane.com/" + this.delivOrder.deliv_photo;
      return null;
    },
  },
};
</script>
