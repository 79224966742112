import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.filteredDrivers),function(driver,index){return _c(VListItem,{key:index},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(driver.name))])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }