class MapUtils {
  static getGeoCode(address) {
    var geoCodeUrl = 'https://maps.googleapis.com/maps/api/geocode/json?' // may need to set apiKey
    const apiKey = 'AIzaSyA-cF8-f3ZFxVU8WrI6WIhLJKeRq4IkOFc'

    function generateUrl(address) {
      address = [address.line1, address.city, address.state, address.country]
      address = address.join(',')
      address = address.replace(/\s+/g, '+') // replace space with '+'
      return geoCodeUrl + 'address=' + address + '&key=' + apiKey
    }
    var url = generateUrl(address)
    return window.axios2.get(url)
  }
  static getTimeZone(lat, lng) {
    const location = lat + ',' + lng
    const timeZoneUrl = 'https://maps.googleapis.com/maps/api/timezone/json?'
    const apiKey = 'AIzaSyA-cF8-f3ZFxVU8WrI6WIhLJKeRq4IkOFc'
    var time = new Date()
    time = time.getSeconds()
    var url = timeZoneUrl + 'location=' + location + '&timestamp=' + time + '&key=' + apiKey
    return window.axios2.get(url)
  }
  static loadCountriesStates() {
    const url = process.env.VUE_APP_MEDIAHOST + "public/json/countries_and_states.json";
    return new Promise((resolve) => {
      window.axios2.get(url).then(response => {
        resolve(response.data)
      });
    })
  }
}

export default MapUtils