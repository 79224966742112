import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"mb-3",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"10","md":"4"}},[_c(VTextField,{attrs:{"dense":"","clearable":"","append-icon":"search","label":"Search Business","solo":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.bizs,"sort-by":"name","search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.phones",fn:function(ref){
var item = ref.item;
return [(item.phones && item.phones.length)?_c('span',[_vm._v(_vm._s(_vm._f("phone")(item.phones[0].number)))]):_vm._e()]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("address")(item.address)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }