<template lang="pug">
v-simple-table(dense)
  tbody
    tr
      td Fee
      td.text-right {{ totalFee | currency }}
    tr
      td Tip
      td.text-right {{ totalTip | currency }}
    tr
      td Reward
      td.text-right {{ totalReward | currency }}
    tr.font-weight-bold
      td Total
      td.text-right {{ total | currency }}
    tr.font-weight-bold
      td Per Delivery
      td.text-right {{ average | currency }}
</template>

<script>
import _ from "underscore";
export default {
  props: ["list"],
  computed: {
    average() {
      if (this.list && this.list.length) {
        return this.total / this.list.length;
      } else {
        return 0;
      }
    },
    total() {
      return this.totalFee + this.totalTip + this.totalReward;
    },
    totalFee() {
      if (!this.list) return 0;
      return _.reduce(
        this.list,
        (memo, item) => {
          return memo + item.fee;
        },
        0
      );
    },
    totalTip() {
      if (!this.list) return 0;
      return _.reduce(
        this.list,
        (memo, item) => {
          return memo + item.tip;
        },
        0
      );
    },
    totalReward() {
      if (!this.list) return 0;
      return _.reduce(
        this.list,
        (memo, item) => {
          return memo + item.reward;
        },
        0
      );
    },
  },
};
</script>
