import _ from "underscore";

const state = {
  bizs: []
};

const getters = {
  bizs: state => state.bizs
};

const actions = {
  setBizs: ({
    commit
  }, bizs) => {
    commit("setBizs", bizs);
  },
  updateBiz: ({
    commit
  }, biz) => {
    commit("updateBiz", biz);
  },
  addBiz: ({
    commit
  }, biz) => {
    commit("addBiz", biz);
  },
  removeBiz: ({
    commit
  }, biz) => {
    commit("removeBiz", biz);
  }
};

const mutations = {
  setBizs(state, bizs) {
    state.bizs = _.sortBy(bizs, 'name');
  },
  updateBiz(state, biz) {
    state.bizs = _.map(state.bizs, o => {
      if (o._id === biz._id) return biz;
      return o;
    });
  },
  addBiz(state, biz) {
    state.bizs.push(biz);
  },
  removeBiz(state, biz) {
    state.bizs = _.reject(state.bizs, o => {
      return o._id === biz._id;
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};