<template lang="pug">
.pa-3
  Table(v-if="drivers && drivers.length")
  Drivers
</template>

<script>
import Table from "./Table";
import Drivers from "./Drivers";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { Table, Drivers },
  computed: {
    ...mapGetters(["deliv", "drivers"]),
  },
  methods: {
    ...mapActions(["setDrivers"]),
    async loadDrivers() {
      if (!this.deliv || !this.deliv._id) return;
      const params = {
        criteria: { deliv: this.deliv._id, status: { $ne: false } },
      };
      const res = await this.axios.post("/driver/accounts/list", params);
      this.setDrivers(res.data);
    },
  },
  mounted() {
    this.loadDrivers();
  },
};
</script>
