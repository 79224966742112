import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"wrap":"","dense":""}},_vm._l((_vm.items),function(item,index){return _c(VCol,{key:index,attrs:{"cols":"12","sm":"6","md":"3"}},[_c(VCard,[_c(VCardText,[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-account")]),_c('span',[_vm._v(_vm._s(item.name))])],1),_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-phone")]),_c('span',[_vm._v(_vm._s(_vm._f("phone")(item.phone)))])],1),_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-email")]),_c('span',[_vm._v(_vm._s(item.email))])],1),_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-map")]),_c('div',_vm._l((item.zones),function(zone,izone){return _c('span',{key:izone + 'zone',staticClass:"ml-2"},[_vm._v(_vm._s(zone))])}),0)],1),_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c(VSpacer),_c('span',[_vm._v(_vm._s(_vm._f("date")(item.created)))])],1)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"text-capitalize",attrs:{"color":"secondary","rounded":""},on:{"click":function($event){return _vm.handleHire(item)}}},[_vm._v("Hire")]),_c(VBtn,{staticClass:"text-capitalize",attrs:{"color":"error","rounded":""},on:{"click":function($event){return _vm.handleReject(item)}}},[_vm._v("Reject")])],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }