<template lang="pug">
div
  Summary.my-3(:list="list")
  DayTable.my-3(:list="list")
  DriverTable.my-3(:list="list")
  RestaurantTable.my-3(:list="list")
</template>

<script>
import Summary from "./Summary";
import DriverTable from "./DriverTable";
import DayTable from "./DayTable";
import RestaurantTable from "./RestaurantTable";

export default {
  name: "ReportList",
  props: ["list"],
  components: { Summary, DriverTable, DayTable, RestaurantTable },
};
</script>
