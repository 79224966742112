<template lang="pug">
div
  v-btn.text-capitalize(
    @click="open()",
    outlined,
    small,
    depressed,
    color="grey darken-2"
  ) Edit
    v-icon(right, small) mdi-pencil
  v-dialog(v-model="dialog", width="350")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Basic Information
      v-card-text
        v-form(@submit.prevent="submit")
          v-text-field(
            v-model="phone",
            v-mask="phoneMask",
            label="Phone",
            :error-messages="phoneErrors",
            @blur="$v.phone.$touch()"
          )
          v-text-field(
            label="Email",
            v-model="email",
            @blur="$v.email.$touch()",
            :error-messages="emailErrors"
          )
          v-text-field(
            label="CC Recipients",
            v-model="carboncopy",
            hint="Extra email recipients. Separate with comma.",
            placeholder="a@gmail.com, b@gmail.com"
          )
          v-select(
            label="Country",
            name="state",
            v-model="country",
            :items="countriesStates",
            item-text="name",
            item-value="abbreviation",
            @change="states = []"
          )
          v-select(
            label="Serving States",
            v-model="states",
            :items="stateItems",
            item-text="name",
            item-value="abbreviation",
            multiple
          )
          v-btn(
            block,
            color="secondary",
            type="submit",
            :disabled="$v.$invalid"
          ) Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import MapUtils from "@/utils/map";
import _ from "underscore";

export default {
  mixins: [validationMixin],
  directives: { mask },
  validations: {
    phone: { required },
    email: { email, required },
    states: { required },
  },
  data() {
    return {
      dialog: false,
      phone: "",
      email: "",
      carboncopy: "",
      country: "US",
      states: [],
      countriesStates: [],
      phoneMask: "(###) ###-####",
    };
  },
  computed: {
    ...mapGetters(["deliv"]),
    stateItems() {
      if (!this.countriesStates || !this.country) return [];
      const country = _.find(
        this.countriesStates,
        (o) => o.abbreviation == this.country
      );
      if (!country) return [];
      return country.states;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push("Phone is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Invalid email address");
      return errors;
    },
  },
  methods: {
    ...mapActions(["setDeliv"]),
    open() {
      if (!this.deliv) return;
      this.dialog = true;
      this.phone = this.deliv.phone;
      this.email = this.deliv.email;
      this.carboncopy = this.deliv.carboncopy;
      this.states = this.deliv.states;
      this.country = this.deliv.country || "US";
      this.$v.$reset();
    },
    async submit() {
      if (!this.deliv) return;
      this.dialog = false;
      const options = {
        criteria: { _id: this.deliv._id },
        action: {
          $set: {
            phone: this.phone.replace(/[^\d]/g, ""),
            email: this.email,
            carboncopy: this.carboncopy,
            country: this.country,
            states: this.states.sort(),
          },
        },
      };
      const res = await this.axios.post("/deliv/update", options);
      this.setDeliv(res.data);
    },
  },
  mounted() {
    MapUtils.loadCountriesStates().then((data) => {
      this.countriesStates = data;
    });
  },
};
</script>
