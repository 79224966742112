<template lang="pug">
v-btn(icon @click='defaultBank()' :loading='loading').mr-1
  v-icon(small).fa.fa-certificate
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "SetDefaultBank",
  props: ["bankAccount"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["stripeAccount"]),
  },
  methods: {
    defaultBank() {
      if (!this.stripeAccount) return;
      if (!this.bankAccount) return;
      this.loading = true;
      const options = {
        accountId: this.stripeAccount.id,
        bankId: this.bankAccount.id,
      };
      this.axios.post("/stripe/bankaccounts/set_default", options).then(() => {
        EventBus.$emit("reload-bank-account");
        this.loading = false;
      });
    },
  },
};
</script>
