<template lang="pug">
div
  v-card
    v-toolbar(flat color='teal darken-2' dense dark)
      v-toolbar-title.body-2 Special Schedule
      v-spacer
      v-btn(icon @click='open()')
        v-icon(small).fa.fa-plus
    v-list(dense v-if='deliv').py-0
      v-list-item(v-for='(item, index) in deliv.schedule.specialdays' :key='index' @click.stop='open(item)')
        v-list-item-content {{ item.date }}
        v-list-item-content.align-end
          span(v-if='item.status') {{ item.schedule | dayschedule }}
          span(v-else) Closed
        v-list-item-action
          v-btn(icon @click.stop='remove(item)')
            v-icon(small color="red").fa.fa-trash
    v-btn(block @click='closeToday').red--text.my-0 close today
  v-dialog(v-model='dialog' width="500")
    v-card(v-if='day && day.date')
      v-form(@submit.prevent='submit')
        v-container
          v-layout(row justify-space-between)
            v-flex(xs6)
              v-menu(lazy transition='scale-transition' offset-y='' full-width='' :nudge-right='40' max-width='290px' min-width='290px')
                v-text-field(slot='activator' v-model='day.date' prepend-icon='event' readonly='' hide-details)
                v-date-picker(v-model='day.date' :min='minDate')
            v-flex(xs3)
              v-switch(v-model='day.status' :label='dayStatus')
          v-layout(row v-for='(s, index) in day.schedule' :key='index' v-if='day.status' align-baseline)
            v-flex(xs5)
              v-select(v-model='day.schedule[index].range[0]' :items='items' menu-props='auto' dense hide-details label='Start')
            v-flex(xs5)
              v-select(v-model='day.schedule[index].range[1]' :items='items' menu-props='auto' dense hide-details label='End')
            v-flex(xs2)
              v-icon(small color='red' v-if='day.schedule.length>1' @click='removeRange(index)').fa.fa-times.mx-2
              v-icon(small color='secondary' v-if='index===day.schedule.length-1' @click='addRange()').fa.fa-plus.mx-2
          v-btn(block color="secondary" type='submit').mt-3 Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      items: [],
      day: null,
      minDate: null
    };
  },
  computed: {
    ...mapGetters(["deliv"]),
    dayStatus() {
      if (this.day && this.day.status) return "Open";
      else return "Closed";
    }
  },
  methods: {
    ...mapActions(["setDeliv"]),
    setItems() {
      const minutesToTime = this.$options.filters.minutesToTime;
      this.items = _.map(_.range(0, 1470, 30), o => {
        return {
          value: o,
          text: minutesToTime(o)
        };
      });
    },
    open(day) {
      if (!this.deliv) return;
      const todayDate = moment().format("YYYY-MM-DD");
      this.minDate = todayDate;
      if (day) {
        this.day = JSON.parse(JSON.stringify(day));
        this.day.date = moment(this.day.date, "YYYY/M/D").format("YYYY-MM-DD");
      } else {
        this.day = {
          date: todayDate,
          status: false,
          schedule: [{ range: [480, 1200] }]
        };
      }
      this.dialog = true;
    },
    addRange() {
      if (this.day.schedule) {
        this.day.schedule.push({ range: [480, 1200] });
      } else {
        this.day.schedule = [{ range: [480, 1200] }];
      }
    },
    removeRange(index) {
      if (this.day && this.day.schedule && index < this.day.schedule.length) {
        this.day.schedule.splice(index, 1);
      }
    },
    submit() {
      if (!this.deliv) return;
      const found = _.find(this.day.schedule, s => {
        return s.range.length !== 2 || s.range[0] >= s.range[1];
      });
      if (found && this.day.status) {
        alert("Closing time should be greater than opening time");
        return;
      }
      if (
        this.day.status &&
        (!this.day.schedule || this.day.schedule.length === 0)
      ) {
        alert("Please add start/end time slot");
        return;
      }
      const cloneDay = JSON.parse(JSON.stringify(this.day));
      cloneDay.date = moment(cloneDay.date, "YYYY-MM-DD").format("YYYY/M/D");
      if (!cloneDay.status) {
        cloneDay.schedule = [];
      } else {
        cloneDay.schedule = _.sortBy(cloneDay.schedule, item => {
          return item.range[0];
        });
      }
      const specialdays = JSON.parse(
        JSON.stringify(this.deliv.schedule.specialdays)
      );
      const result = this.validateSpecialdays(cloneDay, specialdays);
      if (result.errors && result.errors.length > 0) {
        alert(result.errors.join("; "));
        return;
      }
      this.dialog = false;
      const options = {
        criteria: { _id: this.deliv._id },
        action: { "schedule.specialdays": result.specialdays }
      };
      this.axios.post("/deliv/update", options).then(response => {
        this.setDeliv(response.data);
      });
    },
    isPastDate(date) {
      const beginOfToday = moment()
        .startOf("days")
        .unix();
      return moment(date, "YYYY/M/D").unix() < beginOfToday;
    },
    validateSpecialdays(day, specialdays) {
      if (!day || !day.date) {
        return { errors: ["Empty data"], specialdays: specialdays };
      }
      if (this.isPastDate(day.date)) {
        return { errors: ["Past date"], specialdays: specialdays };
      }
      // replace or insert day
      let replaced = false;
      specialdays = _.map(specialdays, function(item) {
        if (item.date === day.date) {
          replaced = true;
          return day;
        } else return item;
      });
      if (!replaced) specialdays.push(day);
      specialdays = _.chain(specialdays)
        .reject(item => {
          return this.isPastDate(item.date);
        })
        .sortBy(item => {
          return moment(item.date).unix();
        })
        .value();
      return { errors: [], specialdays: specialdays };
    },
    closeToday() {
      this.day = {
        date: moment().format("YYYY-MM-DD"),
        status: false,
        schedule: [{ range: [480, 1200] }]
      };
      this.submit();
    },
    remove(item) {
      if (!this.deliv || !item._id) return;
      const options = {
        criteria: { _id: this.deliv._id },
        action: { $pull: { "schedule.specialdays": { _id: item._id } } }
      };
      this.axios.post("/deliv/update", options).then(response => {
        this.setDeliv(response.data);
      });
    }
  },
  mounted() {
    this.setItems();
  }
};
</script>
