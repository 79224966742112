<template lang="pug">
div(v-if="!loading")
  Account
  Persons(v-if="stripeAccount && stripeAccount.business_type == 'company'")
  Banks(v-if="stripeAccount")
  Balance(v-if="stripeAccount")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Account from "./Account/index";
import Persons from "./Persons/index";
import Banks from "./Banks/index";
import Balance from "./Balance/index";

export default {
  components: { Account, Persons, Banks, Balance },
  data() {
    return {
      loading: false, // hide the view when loading initially
    };
  },
  computed: {
    ...mapGetters(["deliv", "stripeAccount"]),
  },
  methods: {
    ...mapActions(["setStripeAccount"]),
    async load() {
      if (!this.deliv) return;
      this.loading = true;
      try {
        const params = { delivId: this.deliv._id };
        const res = await this.axios.post("/stripe/accounts/retrieve", params);
        this.setStripeAccount(res.data);
      } catch (err) {
        //
      }
      this.loading = false;
    },
  },
  mounted() {
    this.setStripeAccount(null);
    this.load();
  },
};
</script>