<template lang="pug">
div
  v-btn.text-capitalize(
    @click="add()",
    outlined,
    small,
    depressed,
    :loading="loading",
    color="grey darken-2"
  ) Add
    v-icon(right, small) mdi-plus
  v-dialog(v-model="dialog", width="500", @keydown.esc="dialog = false")
    v-card
      v-card-title.grey.lighten-2 Add Clients
      v-container
        v-form(@submit.prevent="submit")
          v-select(v-model="bizId", :items="bizItems", label="Restaurant")
          v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  name: "DelivBizForm",
  data() {
    return {
      dialog: false,
      loading: false,
      bizId: [],
    };
  },
  computed: {
    ...mapGetters(["deliv", "bizs"]),
    bizItems() {
      if (!this.bizs) return [];
      return _.map(this.bizs, (o) => {
        return {
          value: o._id,
          text: o.name + ", " + o.address.city,
        };
      });
    },
  },
  methods: {
    add() {
      if (!this.deliv) return;
      this.bizId = [];
      this.dialog = true;
    },
    submit() {
      if (!this.deliv) return;
      const params = { delivId: this.deliv._id, bizId: this.bizId };
      this.loading = true;
      this.axios.post("/delivbiz/create", params).then(
        () => {
          this.loading = false;
          this.$emit("done");
          this.dialog = false;
        },
        (err) => {
          this.loading = false;
          if (err.response) alert(err.response.data);
        }
      );
    },
  },
};
</script>
