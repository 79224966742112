import _ from 'underscore'
import moment from 'moment'

class BizUtils {
  static getCategories(numbers) {
    if (!numbers) return ''
    const categories = [{
        name: 'Other',
        value: '9999'
      },
      {
        name: 'American',
        value: '1'
      },
      {
        name: 'Chinese',
        value: '2'
      },
      {
        name: 'French',
        value: '3'
      },
      {
        name: 'Japanese',
        value: '4'
      },
      {
        name: 'Korean',
        value: '5'
      },
      {
        name: 'Greek',
        value: '6'
      },
      {
        name: 'Indian',
        value: '7'
      },
      {
        name: 'Italian',
        value: '8'
      },
      {
        name: 'Mexican',
        value: '9'
      },
      {
        name: 'Thai',
        value: '10'
      },
      {
        name: 'Vietnamese',
        value: '11'
      },
      {
        name: 'Brazilian',
        value: '12'
      },
      {
        name: 'Cajun/Creole',
        value: '13'
      },
      {
        name: 'Caribbean',
        value: '14'
      },
      {
        name: 'Cuban',
        value: '15'
      },
      {
        name: 'Greek and Mediterranean',
        value: '16'
      },
      {
        name: 'Hawaiian',
        value: '17'
      },
      {
        name: 'Brewpub',
        value: '18'
      }
    ]
    let list = []
    numbers.forEach(num => {
      let found = _.find(categories, o => {
        return o.value === num
      })
      if (found) {
        list.push(found.name)
      }
    })
    return list.join(', ')
  }

  static getSchedule(schedule, time) {
    if (!time) {
      time = moment()
    }
    const dayOfWeek = time.day()
    const date = time.format('YYYY/M/D')
    // 1) check specialdays
    const foundSpecial = _.find(schedule.specialdays, o => {
      return o.date === date
    })
    if (foundSpecial) {
      return this.formatSchedule(foundSpecial)
    } else {
      const foundWeekday = _.find(schedule.weekdays, o => {
        return o.date === dayOfWeek
      })
      return this.formatSchedule(foundWeekday)
    }
  }

  static formatSchedule(day) {
    if (!day.status) {
      return 'closed'
    } else {
      return this.stringFromBizSchedule(day.schedule)
    }
  }

  static geoDistance(pos1, pos2) {
    // ref http://www.movable-type.co.uk/scripts/latlong.html
    // use Equirectangular approximation
    var radius = 3959 // earth radius in miles
    var phi1 = toRadius(pos1.lat)
    var phi2 = toRadius(pos2.lat)
    var lamda1 = toRadius(pos1.lng)
    var lamda2 = toRadius(pos2.lng)
    var x = (lamda2 - lamda1) * Math.cos((phi1 + phi2) / 2)
    var y = phi2 - phi1
    var d = Math.sqrt(x * x + y * y) * radius

    function toRadius(input) {
      return input * Math.PI / 180
    }
    return d
  }

  static geoDistance2(pos1, pos2, unit = 'mi.') {
    // ref http://www.movable-type.co.uk/scripts/latlong.html
    // use Equirectangular approximation
    const radius = 6378000 // earth radius in meters
    const phi1 = toRadius(pos1.lat)
    const phi2 = toRadius(pos2.lat)
    const lamda1 = toRadius(pos1.lng)
    const lamda2 = toRadius(pos2.lng)
    const x = (lamda2 - lamda1) * Math.cos((phi1 + phi2) / 2)
    const y = phi2 - phi1
    let d = Math.sqrt(x * x + y * y) * radius

    function toRadius(input) {
      return input * Math.PI / 180
    }

    if (unit == "mi.") {
      d *= 0.000621371
    } else {
      d *= 0.001
    }

    return {
      value: d,
      unit: unit
    }
  }

  static stringFromBizSchedule(input) {
    var str = []
    input.forEach(o => {
      if (o.range && o.range.length === 2) {
        var begin = this.fromMinutesToTime(o.range[0])
        var end = this.fromMinutesToTime(o.range[1])
        str.push(begin + ' - ' + end)
      }
    })
    return str.join(', ')
  }

  static fromMinutesToTime(minutes) {
    if (isNaN(minutes)) {
      return 'N/A'
    }
    minutes = Math.rounded(minutes) // ensure integer
    var hh = Math.floor(minutes / 60)
    var ampm = 'AM'
    if (hh >= 12) {
      ampm = 'PM'
      hh -= 12
    }
    if (hh === 0) hh = 12
    hh = String(hh)
    var mm = String(minutes % 60)
    if (mm.length === 1) mm = '0' + mm
    return hh + ':' + mm + ' ' + ampm
  }

  static digitsToWeekdays(digits) {
    return _.chain(digits).sort().map(o => {
      switch (o) {
        case 0:
          return 'Sunday'
        case 1:
          return 'Monday'
        case 2:
          return 'Tuesday'
        case 3:
          return 'Wednesday'
        case 4:
          return 'Thursday'
        case 5:
          return 'Friday'
        case 6:
          return 'Saturday'
        default:
          return ''
      }
    }).value().join(', ')
  }
}

export default BizUtils