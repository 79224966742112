<template lang="pug">
div(style='width: 125px;')
  v-select(:items='months' v-model='selected' hide-details outlined dense)
</template>

<script>
import _ from "underscore";
import moment from "moment";

export default {
  name: "MonthSelector",
  data() {
    return {
      selected: "",
      months: []
    };
  },
  watch: {
    selected() {
      this.send();
    }
  },
  methods: {
    prepare() {
      /// prepare available months
      const nowYear = moment().year();
      const nowMonth = moment().month();
      let year = 2017;
      this.months = [];
      while (year < nowYear) {
        this.months = _.map(_.range(12), m => {
          return String(year) + "-" + String(m + 1).padStart(2, "0");
        });
        year++;
      }
      let month = 0;
      while (month <= nowMonth) {
        this.months.push(
          String(nowYear) + "-" + String(month + 1).padStart(2, "0")
        );
        month++;
      }
      this.months.reverse();
      this.selected = this.months[0];
    },
    send() {
      const range = {
        begin:
          moment(this.selected, "YYYY-MM")
            .startOf("months")
            .unix() * 1000,
        end:
          moment(this.selected, "YYYY-MM")
            .endOf("months")
            .unix() * 1000
      };
      this.$emit("select", {
        name: this.selected,
        range: range
      });
    }
  },
  mounted() {
    // send in initiating
    this.prepare();
    this.send();
  }
};
</script>
