<template lang="pug">
div
  v-btn(@click='add()' outlined small depressed color='grey darken-2' :loading='loading').text-capitalize Add
    v-icon(right small) mdi-plus
  v-dialog(v-model='dialog' width="350")
    v-card
      v-card-text
        .sl-title Add an account
        v-form(@submit.prevent='submit')
          v-text-field(
            label='Email'
            v-model='email'
            :error-messages='emailErrors'
            @blur='$v.email.$touch()')
          v-select(
            label='Role' 
            v-model='role'
            :items='role_items'
            :error-messages='roleErrors'
            @blur='$v.role.$touch()')
          v-btn(block color="secondary" type="submit" :disabled='$v.$invalid' :loading='loading') Save
        .sl-secondary-text The targeted account should be a registered user with Selflane. Otherwise, please ask the person to sign up first.
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "CreatePerson",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    role: { required }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      email: "",
      role: null,
      role_items: ["owner", "manager"]
    };
  },
  methods: {
    ...mapActions(["addPerson"]),
    add() {
      this.dialog = true;
      this.email = "";
      this.role = null;
      this.$v.$reset();
    },
    submit() {
      if (!this.deliv) return;
      const options = {
        userEmail: this.email,
        delivId: this.deliv._id,
        role: this.role
      };
      this.loading = true;
      this.axios.post("/persons/create", options).then(
        response => {
          this.loading = false;
          this.dialog = false;
          this.addPerson(response.data);
        },
        err => {
          this.loading = false;
          if (err.response) alert(err.response.data);
        }
      );
    }
  },
  computed: {
    ...mapGetters(["deliv"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.role.$dirty) return errors;
      !this.$v.role.required && errors.push("Role is required");
      return errors;
    }
  }
};
</script>
