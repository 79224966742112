import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.driver)?_c(VCard,[_c(VToolbar,{attrs:{"flat":"","dense":"","color":"error","dark":""}},[_c('div',{staticClass:"sl-title"},[_vm._v("Reject "+_vm._s(_vm.driver.name))])]),_c(VCardText,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Reject Reason"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c(VBtn,{attrs:{"block":"","color":"secondary","type":"submit","loading":_vm.loading}},[_vm._v("Save")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }