/// can access in creating an account /// or update an account that is not yet
verified

<template lang="pug">
div(v-if="stripeAccount && noOwner")
  v-btn.text-capitalize(
    @click="add()",
    @done="done()",
    outlined,
    small,
    depressed,
    color="grey darken-2"
  ) Add
    v-icon(right, small) mdi-plus
  v-dialog(v-model="dialog", max-width="600")
    CreatePersonForm
</template>

<script>
import { mapGetters } from "vuex";
import CreatePersonForm from "./CreatePersonForm";

export default {
  name: "CreatePerson",
  components: { CreatePersonForm },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["stripeAccount", "stripePersons"]),
    noOwner() {
      return !this.stripePersons || !this.stripePersons.length;
    },
  },
  methods: {
    add() {
      this.dialog = true;
    },
    done() {
      this.dialog = false;
    },
  },
};
</script>
