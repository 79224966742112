<template lang="pug">
.pa-3
  TopBar
  List
  OrderDetail
  AssignDriver
  TransferDriver
  Redeliver
  OntimeScore
</template>

<script>
import TopBar from "./TopBar/index";
import List from "./List/index";
import OrderDetail from "./OrderDetail";
import AssignDriver from "./AssignDriver/index";
import TransferDriver from "./TransferDriver/index";
import Redeliver from "./Redeliver/index";
import OntimeScore from "./OntimeScore";

export default {
  components: {
    TopBar,
    List,
    OrderDetail,
    AssignDriver,
    Redeliver,
    TransferDriver,
    OntimeScore,
  },
};
</script>
