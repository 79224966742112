import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VBtn,{staticClass:"text-capitalize",attrs:{"outlined":"","small":"","depressed":"","color":"grey darken-2","loading":_vm.loading},on:{"click":function($event){return _vm.add()}}},[_vm._v("Add"),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-plus")])],1),_c(VDialog,{attrs:{"width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":"","dense":""}},[_c('div',{staticClass:"subtitle-2"},[_vm._v("Add a Driver")])]),_c(VCardText,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Name","error-messages":_vm.nameErrors},on:{"blur":function($event){return _vm.$v.name.$touch()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],attrs:{"label":"Phone","error-messages":_vm.phoneErrors},on:{"blur":function($event){return _vm.$v.phone.$touch()}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c(VTextField,{attrs:{"label":"Email","error-messages":_vm.emailErrors},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),_c(VBtn,{attrs:{"block":"","color":"secondary","type":"submit","disabled":_vm.$v.$invalid,"loading":_vm.loading}},[_vm._v("Save")])],1),_c('div',{staticClass:"caption"},[_vm._v("A driver should be a registered user with Selflane. Otherwise, please ask her/him to sign up first.")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }