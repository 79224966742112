/// Select a week range, use isoWeek -- starts on Monday
<template lang="pug">
v-card.pa-3(outlined)
  v-row(align="center", justify="center")
    v-btn(icon, small, @click="previous()", title="previous")
      v-icon mdi-menu-left
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        .date-title(v-on="on", title="open date selector") {{ formatedDay }}
      v-date-picker(v-model="day", :max="maxDate")
    v-btn(icon, small, @click="next()", title="next")
      v-icon mdi-menu-right
</template>

<script>
import moment from "moment";
export default {
  name: "dayselector",
  props: ["format"],
  data() {
    return {
      day: moment().format("YYYY-MM-DD"),
    };
  },
  watch: {
    // send whenever begin is edited, either by clicking next/previous buttons or by selecting date
    day() {
      this.send();
    },
  },
  computed: {
    formatedDay() {
      if (this.format) return moment(this.day).format(this.format);
      else return this.day;
    },
    maxDate() {
      return moment().format("YYYY-MM-DD");
    },
  },
  methods: {
    next() {
      this.day = moment(this.day).add(1, "day").format("YYYY-MM-DD");
    },
    previous() {
      this.day = moment(this.day).subtract(1, "day").format("YYYY-MM-DD");
    },
    send() {
      const unix = moment(this.day).unix() * 1000;
      this.$emit("select", unix);
    },
  },
  mounted() {
    this.send();
  },
};
</script>

<style scoped>
.date-title {
  cursor: pointer;
  padding-left: 4px;
  padding-right: 4px;
}
</style>