<template lang="pug">
div(v-if="stripeAccount")
  v-btn.text-capitalize(
    @click="add()",
    outlined,
    small,
    depressed,
    :loading="loading",
    color="grey darken-2"
  ) Add
    v-icon(right, small) mdi-plus
  v-dialog(v-model="dialog", max-width="400")
    v-card(v-if="bank")
      v-toolbar(flat, dense)
        .subtitle-2 Add Bank Account
      v-card-text
        v-form(@submit.prevent="submit")
          v-row(dense)
            v-col
              v-text-field(disabled, v-model="bank.country", label="Country")
            v-col
              v-text-field(disabled, v-model="bank.currency", label="Currency")
          v-text-field(label="Routing Number", v-model="bank.routing_number" v-if='isUS')
          v-row(dense v-else)
            v-col(cols='6')
              v-text-field(label="Transit Number", v-model="transit")
            v-col(cols='6')
              v-text-field(label="Institution Number", v-model="institution")
          v-text-field(label="Account Number", v-model="bank.account_number")
          v-text-field(
            label="Confirm Account Number",
            v-model="repeat_account_number"
          )
          v-btn(block, color="secondary", type="submit", :loading="loading") Save
          .red--text {{ errors }}
          .caption Please double check the rounting and account number. The fund will be transfered to your bank account automatically every Monday.
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  name: "CreateBankAccount",
  data() {
    return {
      dialog: false,
      loading: false,
      transit: "",
      institution: "",
      bank: {
        country: "",
        currency: "",
        routing_number: "",
        account_number: "",
      },
      repeat_account_number: "",
      errors: "",
    };
  },
  computed: {
    ...mapGetters(["stripeAccount"]),
    isUS() {
      return this.stripeAccount.country == "US";
    },
  },
  methods: {
    add() {
      this.transit = "";
      this.institution = "";
      this.bank = {
        country: this.stripeAccount.country,
        currency: this.stripeAccount.default_currency,
        routing_number: "",
        account_number: "",
      };
      this.repeat_account_number = "";
      this.dialog = true;
    },
    validate() {
      if (!this.stripeAccount) return "No connected account";
      if (!this.bank) return "Invalid bank object";
      if (!this.bank.country) return "Invalid country";
      if (!this.bank.currency) return "Invalid currency";
      if (!this.bank.routing_number) return "Invalid routing number";
      if (!this.bank.account_number) return "Invalid account number";
      if (!this.repeat_account_number) return "Invalid confirm account number";
      if (this.repeat_account_number != this.bank.account_number) return "Confirm account number does not match";
      return null;
    },
    /// get token for bank account
    submit() {
      const stripe = window.Stripe(process.env.VUE_APP_STRIPE);
      if (!this.isUS) this.bank.routing_number = this.transit.trim() + "-" + this.institution.trim();
      this.errors = this.validate();
      if (this.errors) return;
      this.loading = true;
      stripe
        .createToken("bank_account", this.bank)
        .then(data => {
          this.loading = false;
          if (data && data.token && data.token.id) {
            this.dialog = false;
            this.createBank(data.token.id);
          } else {
            let message = "Failed to generate token";
            if (data.error.message) message = data.error.message;
            alert(message);
          }
        })
        .catch(err => {
          this.loading = false;
          if (err.response) alert(err.response.data);
        });
    },
    async createBank(token) {
      if (!this.stripeAccount) return;
      this.loading = true;
      const params = {
        accountId: this.stripeAccount.id,
        token: token,
      };
      await this.axios.post("/stripe/bankaccounts/create", params);
      this.loading = false;
      EventBus.$emit("reload-bank-account");
    },
  },
};
</script>
