<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card(v-if="order")
    v-card-title
    v-card-text.text-center
      v-alert(
        color="error",
        :value="order.cancelNote",
        v-if="order.cancelNote"
      ) {{ order.cancelNote }}
      .title {{ order.seller.name }}
      .title Ticket: \#{{ order.orderNumber }}
      .subtitle-2 {{ order.seller.address | address }}
      .subtitle-2 {{ order.seller.phone | phone }}
      .d-flex.flex-row.justify-center
        v-icon.mr-2 mdi-account-circle
        .font-weight-medium {{ order.customer.name }} {{ order.customer.phone | phone }}
      v-divider.my-2
      .subtitle-2 {{ order.needed | datetime }}
      div Server: {{ order.orderer.name }}
    v-simple-table
      tbody
        tr(v-for="(dish, index) in order.dishes", :key="index + 'dish'")
          td
            .d-flex.flex-row.align-center
              .subtitle-2.mr-1 {{ dish.quantity }}&#215;
              div
                .subtitle-2 {{ dish.name }}
                  span.ml-4.red--text(v-if="dish.pctOff > 0") {{ dish.pctOff }}% off
                .green--text {{ dish.modifiers | modifiers }}
          td.text-right {{ dish.unitPrice * dish.quantity | currency }}
        tr(v-for="(item, index) in order.gifts", :key="index + 'gift'")
          td Gift Card
          td.text-right {{ item.amount | currency }}
        tr(v-for="(item, index) in order.vouchers", :key="index + 'voucher'")
          td {{ item.name }}
          td.text-right.red--text {{ -item.amount | currency }}
        tr
          td Total
          td.text-right {{ order.payment.total | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  name: "OrderDetail",
  data() {
    return {
      order: null,
      dialog: false,
    };
  },
  methods: {
    async load(orderId) {
      if (!orderId) return;
      this.dialog = true;
      const options = { criteria: { _id: orderId } };
      const res = await this.axios.post("/orders/retrieve", options);
      this.order = res.data;
    },
  },
  mounted() {
    EventBus.$on("show-order-detail", this.load);
  },
  destroyed() {
    EventBus.$off("show-order-detail");
  },
};
</script>
