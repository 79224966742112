<template lang="pug">
.pa-3
  .d-flex.flex-row.align-center
    .sl-title Clients
    v-spacer
    .d-flex.flex-row.justify-end
      v-btn.text-capitalize.ml-3(
        v-for="(item, index) in items",
        :key="index",
        small,
        active-class="accent",
        :active="item.url === $route.path",
        :to="item.url",
        rounded,
        depressed
      ) {{ item.title }}
  v-divider.mb-3
  router-view
  LoadRestaurants
</template>

<script>
import LoadRestaurants from "./LoadRestaurants";
export default {
  components: { LoadRestaurants },
  data() {
    return {
      items: [
        { title: "Selflane", url: "/clients/selflane" },
        { title: "Others", url: "/clients/others" },
      ],
    };
  },
};
</script>
