<template lang="pug">
v-card.my-3
  v-card-text
    v-layout(align-center)
      .caption.sl-secondary-text Company Owner
      v-spacer
      CreatePerson
    v-divider.mt-2
  List
</template>

<script>
import List from "./List";
import CreatePerson from "./CreatePerson";

export default {
  components: { List, CreatePerson }
};
</script>
