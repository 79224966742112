import Vue from "vue";
import Router from "vue-router";

import Home from '@/components/Home/index.vue'
import CreateDeliv from "@/components/CreateDeliv/index.vue";
import Profile from '@/components/Profile/index.vue'
import Schedules from '@/components/Schedules/index.vue'
import Login from "@/components/User/Login/index.vue";
import ResetPWD from "@/components/User/Login/ResetPWD.vue";
import Persons from '@/components/Persons/index.vue'

import ordersRoute from '@/components/Orders/route'
import clientRoute from '@/components/Clients/route'
import driverRoute from '@/components/Drivers/route'
import financesRoute from '@/components/Finances/route'
import reportRoute from '@/components/Report/route'
import footerRoute from '@/components/Footer/route'

Vue.use(Router)

const baseRoutes = [{
  path: "/",
  name: "Home",
  component: Home
}, {
  path: "/create",
  component: CreateDeliv,
  meta: {
    auth: true
  }
}, {
  path: '/profile',
  component: Profile,
  meta: {
    auth: true,
    role_owner: true
  }
}, {
  path: '/schedules',
  component: Schedules,
  meta: {
    auth: true,
    role_manager: true
  }
}, {
  path: '/persons',
  component: Persons,
  meta: {
    auth: true,
    role_owner: true
  }
}, {
  path: "/login",
  component: Login
}, {
  path: "/resetPWD",
  component: ResetPWD
}, {
  path: "*",
  redirect: "/"
}]

const routes = baseRoutes.concat(ordersRoute, clientRoute, driverRoute, financesRoute, reportRoute, footerRoute)

const router = new Router({
  mode: "history",
  linkActiveClass: "is-active",
  scrollBehavior: () => ({
    y: 0
  }),
  routes: routes
});

/**
 * Use two-layer access protection
 * 1. User login -> auth
 * 2. User role -> DelivUser.role
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.role_owner)) {
    const role = localStorage.getItem('role')
    if (role == 'owner') {
      next()
    } else {
      next({
        path: '/'
      })
    }
  } else if (to.matched.some((record) => record.meta.role_manager)) {
    const role = localStorage.getItem('role')
    if (role == 'owner' || role == 'manager') {
      next()
    } else {
      next({
        path: '/'
      })
    }
  } else {
    next()
  }
})

export default router