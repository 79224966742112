import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"outlined":""}},on),[_vm._v(_vm._s(_vm.selected)),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c(VList,{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.years),function(item,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.select(item)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item))])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }