<template lang="pug">
v-container
  v-layout(row justify-center)
    Login
</template>

<script>
import Login from "./Login";

export default {
  components: { Login }
};
</script>
