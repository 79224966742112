<template lang="pug">
.pa-3
  .d-flex.flex-row.align-center
    .sl-title Drivers
    v-spacer
    CreateDriver
  v-divider.mb-3
  List
</template>

<script>
import CreateDriver from "./CreateDriver";
import List from "./List/index";

export default {
  components: { CreateDriver, List },
};
</script>
