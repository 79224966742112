<template lang="pug">
v-container
  v-layout(row wrap)
    v-flex(sm12 md6)
      Weekly
    v-flex(sm12 md6)
      Special
</template>

<script>
import Weekly from "./Weekly";
import Special from "./Special";

export default {
  components: {
    Weekly,
    Special
  }
};
</script>
