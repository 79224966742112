<template lang="pug">
v-btn(icon @click='handleRemove()' :loading='loading')
  v-icon(small).red--text.fa.fa-trash
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "DeleteBank",
  props: ["bankAccount"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["stripeAccount"]),
  },
  methods: {
    handleRemove() {
      confirm("Do you want to delete this bank account?") && this.remove();
    },
    remove() {
      if (!this.stripeAccount) return;
      if (!this.bankAccount) return;
      this.loading = true;
      const options = {
        accountId: this.stripeAccount.id,
        bankId: this.bankAccount.id,
      };
      this.axios.post("/stripe/bankaccounts/delete", options).then(() => {
        EventBus.$emit("reload-bank-account");
        this.loading = false;
      });
    },
  },
};
</script>
