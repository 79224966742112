<template lang="pug">
.text-truncate.text--secondary.body-2.mb-1(v-if="driver")
  span {{ name }}
  v-chip.ml-1(small)
    DriverOrderCount(:driverId="driverId")
  v-chip.ml-1(small) 
    DriverScore(:driverId="driverId")
  span.ml-1(v-if="current_order") {{ current_order }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import DriverOrderCount from "./DriverOrderCount";
import DriverScore from "./DriverScore";

export default {
  props: ["driverId"],
  components: { DriverOrderCount, DriverScore },
  computed: {
    ...mapGetters(["drivers", "delivOrders"]),
    driver() {
      return _.find(this.drivers, (o) => o._id == this.driverId);
    },
    /// shortened driver name. Up to five charactors
    name() {
      if (!this.driver) return "";
      return this.driver.name.substr(0, 8);
    },
    /// last restaurant
    current_order() {
      const items = _.chain(this.delivOrders)
        .filter(
          (o) =>
            o.driver &&
            o.driver.id == this.driverId &&
            o.status >= 0 &&
            o.status < 4
        )
        .pluck("bizName")
        .uniq()
        .value();
      return items.join(", ");
    },
  },
};
</script>