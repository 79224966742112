const _ = require('underscore')

const state = {
  delivUserList: [],
  delivs: [], // accessible deliv
  deliv: null, // selected deliv
  delivRole: null // user role for the selected deliv
}

const getters = {
  delivUserList: state => state.delivUserList,
  delivs: state => state.delivs,
  deliv: state => state.deliv,
  delivRole: state => state.delivRole
}

const actions = {
  SetDelivUserList: ({
    commit
  }, data) => {
    commit('SetDelivUserList', data)
  },
  setDelivs: ({
    commit
  }, data) => {
    commit('setDelivs', data)
  },
  setDeliv: ({
    commit
  }, data) => {
    commit('setDeliv', data)
  },
  setDelivRole: ({
    commit
  }, data) => {
    commit('setDelivRole', data)
  }
}

const mutations = {
  SetDelivUserList(state, data) {
    state.delivUserList = data
    if (data && data.length > 0) {
      const item = data[0]
      let deliv = _.find(state.delivs, o => o._id == item.deliv)
      this.dispatch('setDeliv', deliv)
      this.dispatch('setDelivRole', item.role)
    } else {
      this.dispatch('setDeliv', null)
      this.dispatch('setDelivRole', null)
    }
  },
  setDelivs(state, data) {
    state.delivs = data
  },
  setDeliv(state, data) {
    state.deliv = data
  },
  setDelivRole(state, data) {
    state.delivRole = data
    localStorage.setItem('role', data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}