<template lang="pug">
.d-flex.flex-row.align-center 
  v-btn.text-capitalize(outlined, color="secondary", small, @click="dispatch") Dispatch
  v-btn.text-capitalize.mx-3(
    @click="load()",
    :loading="loading",
    icon,
    color="secondary",
    small
  )
    v-icon(small) mdi-sync
  DaySelector(@select="load", format="M/D")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import DaySelector from "@/components/basic/DaySelector";
import _ from "underscore";
import { EventBus } from "@/event-bus.js";

export default {
  components: { DaySelector },
  data: () => ({
    selectedDate: new Date(),
    loading: false,
  }),
  computed: {
    ...mapGetters(["deliv"]),
  },
  methods: {
    ...mapActions(["setDelivOrders", "setDrivers"]),
    dispatch() {
      EventBus.$emit("create-manager-order");
    },
    async load(date) {
      if (date) {
        this.selectedDate = date;
      }
      if (!this.deliv || !this.deliv._id) return;
      this.loading = true;
      const begin = moment(this.selectedDate).startOf("day").unix() * 1000;
      const end = moment(this.selectedDate).endOf("day").unix() * 1000;
      const params = {
        criteria: {
          deliv: this.deliv._id,
          needed: { $gte: begin, $lte: end },
          status: { $gte: 0 },
        },
      };
      const res = await this.axios.post("/delivOrders/list", params);
      this.setDelivOrders(_.sortBy(res.data, "needed"));
      this.loading = false;
    },
  },
  mounted() {
    this.load();
  },
};
</script>
