<template lang="pug">
div
  v-btn(icon @click='open()')
    v-icon(color="secondary") mdi-pencil
  v-dialog(v-model='dialog' width='300')
    v-card
      v-card-text
        .sl-title Edit User & Access
        v-form(@submit.prevent='save')
          v-text-field(v-model='name' label='Name')
          v-text-field(v-model='phone' v-mask='phoneMask' label='Phone')
          v-select(v-model='role' :items='role_items' label='Role' v-if='!isMe')
          v-btn(block color="secondary" type='submit') Save
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "EditPerson",
  props: ["person"],
  directives: { mask },
  data() {
    return {
      phoneMask: "(###) ###-####",
      dialog: false,
      name: "",
      phone: "",
      role: "",
      role_items: ["owner", "manager"]
    };
  },
  computed: {
    isMe() {
      return (
        this.person &&
        this.person.user &&
        this.person.user == this.$auth.user()._id
      );
    }
  },
  methods: {
    ...mapActions(["updatePerson"]),
    open() {
      if (!this.person) return;
      this.name = this.person.name;
      this.phone = this.person.phone;
      this.role = this.person.role;
      this.dialog = true;
    },
    save() {
      if (!this.person) return;
      const options = {
        criteria: { _id: this.person._id },
        action: {
          $set: {
            name: this.name.trim(),
            phone: this.phone,
            role: this.role
          }
        }
      };
      this.axios
        .post("/persons/update", options)
        .then(response => {
          this.updatePerson(response.data);
          this.dialog = false;
        })
        .catch(err => {
          if (err.response) alert(err.response.data);
        });
    }
  }
};
</script>
