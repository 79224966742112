import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.driver)?_c(VCard,[_c(VToolbar,{attrs:{"flat":"","dense":""}},[_c('div',{staticClass:"subtitle-2"},[_vm._v("Edit Driver")])]),_c(VCardText,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Name","error-messages":_vm.nameErrors},on:{"blur":function($event){return _vm.$v.name.$touch()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:("(###) ###-####"),expression:"\"(###) ###-####\""}],attrs:{"label":"Phone","error-messages":_vm.phoneErrors},on:{"blur":function($event){return _vm.$v.phone.$touch()}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('div',{staticClass:"mb-10"},[_c('div',{staticClass:"body-2"},[_vm._v("Zones")]),_vm._l((_vm.zone_items),function(item,index){return _c(VCheckbox,{key:index,attrs:{"label":item,"value":item,"color":"secondary","hide-details":""},model:{value:(_vm.zones),callback:function ($$v) {_vm.zones=$$v},expression:"zones"}})})],2),_c(VBtn,{attrs:{"block":"","color":"secondary","type":"submit","disabled":_vm.$v.$invalid}},[_vm._v("Save")])],1),_c(VDivider,{staticClass:"my-5"}),(_vm.driver.status != false)?_c(VBtn,{staticClass:"my-5",attrs:{"block":"","color":"error"},on:{"click":function($event){return _vm.deactivate()}}},[_vm._v("Deactivate")]):_c(VBtn,{staticClass:"my-5",attrs:{"block":"","color":"secondary"},on:{"click":function($event){return _vm.activate()}}},[_vm._v("Activate")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }