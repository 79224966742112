<template lang="pug">
v-data-table(:headers='headers' :items='items' sort-by='year' hide-default-footer)
  template(v-slot:item.actions='{ item }')
    v-btn(small rounded depressed @click='download(item)') Download
</template>

<script>
import FileSaver from "file-saver";

export default {
  props: ["items"],
  data() {
    return {
      headers: [
        { text: "Year", value: "year", sortable: false },
        { text: "Type", value: "filetype", sortable: false },
        { text: "Action", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    download(item) {
      if (!item) return;
      const options = {
        fileid: item._id
      };
      const name = item.filetype + "-" + item.year + ".pdf";
      this.axios
        .post("tax/taxfile/open", options, { responseType: "blob" })
        .then(file => {
          FileSaver.saveAs(file.data, name);
        })
        .catch(err => {
          if (err && err.response) alert(err.response.data);
        });
    }
  }
};
</script>
