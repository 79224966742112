<template lang="pug">
div
  v-btn(@click='add()' outlined small depressed color='grey darken-2' :loading='loading').text-capitalize Add
    v-icon(right small) mdi-plus
  v-dialog(v-model='dialog' width="350")
    v-card
      v-toolbar(flat dense)
        .subtitle-2 Add a Driver
      v-card-text
        v-form(@submit.prevent='submit')
          v-text-field(v-model='name' label='Name' :error-messages='nameErrors' @blur='$v.name.$touch()')
          v-text-field(v-model='phone' v-mask='phoneMask' label='Phone' :error-messages='phoneErrors' @blur='$v.phone.$touch()')
          v-text-field(label='Email' v-model.trim='email' :error-messages='emailErrors' @blur='$v.email.$touch()')
          v-btn(block color="secondary" type="submit" :disabled='$v.$invalid' :loading='loading') Save
        .caption A driver should be a registered user with Selflane. Otherwise, please ask her/him to sign up first.
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "CreateDriver",
  directives: { mask },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    name: { required },
    phone: { required }
  },
  data() {
    return {
      menu: null,
      dialog: false,
      loading: false,
      email: "",
      name: "",
      phone: "",
      phoneMask: "(###) ###-####"
    };
  },
  computed: {
    ...mapGetters(["deliv"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push("Phone is required");
      return errors;
    }
  },
  methods: {
    ...mapActions(["addDriver"]),
    add() {
      this.dialog = true;
      this.email = "";
      this.name = "";
      this.phone = "";
      this.$v.$reset();
    },
    submit() {
      if (!this.deliv) return;
      const options = {
        userEmail: this.email,
        name: this.name.trim(),
        phone: this.phone.replace(/[^\d]/g, ""),
        delivId: this.deliv._id,
        delivName: this.deliv.name
      };
      this.loading = true;
      this.axios.post("/driver/accounts/create", options).then(
        response => {
          this.loading = false;
          this.dialog = false;
          this.addDriver(response.data);
        },
        err => {
          this.loading = false;
          if (err.response) alert(err.response.data);
        }
      );
    }
  }
};
</script>
