<template lang="pug">
div
  Stripe
  Payout
</template>

<script>
import Stripe from "./Stripe/index";
import Payout from "./Payout/index";

export default {
  components: { Stripe, Payout },
};
</script>
