<template lang="pug">
v-dialog(v-model="dialog", max-width="500", scrollable)
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Order Map
    v-card-text
      .d-flex.flex-row.align-center.my-1
        v-chip.mr-2(color="red", dark, small) A: Business
        v-chip.mr-2(color="red", dark, small) B: Customer
        v-chip.mr-2(color="blue", dark, small) Dropoff Point
        v-chip.mr-2(
          color="purple",
          dark,
          small,
          v-if="driver_location && driver_location.lat"
        ) Driver Last Location
      .my-1(v-if="distance") Dropoff Distance: {{ distance }} mi.
      .my-1(v-else-if="driver_location") Driver Last Location: {{ driver_location.time | datetime('MMM D, h:mm A') }}
      #map(ref="map")
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      biz_location: null,
      dialog: false,
      delivOrder: null,
    };
  },
  computed: {
    ...mapGetters(["drivers"]),
    driver() {
      const driverId =
        this.delivOrder && this.delivOrder.driver && this.delivOrder.driver.id;
      if (!driverId) return null;
      return _.find(this.drivers, (o) => o._id == driverId);
    },
    center() {
      if (this.biz_location && this.customer_location) {
        return {
          lat: (this.biz_location.lat + this.customer_location.lat) / 2,
          lng: (this.biz_location.lng + this.customer_location.lng) / 2,
        };
      }
      return this.biz_location;
    },
    driver_location() {
      return this.driver && this.driver.last_location;
    },
    customer_location() {
      return (
        this.delivOrder.customer &&
        this.delivOrder.customer.address &&
        this.delivOrder.customer.address.geometry
      );
    },
    deliv_location() {
      return this.delivOrder && this.delivOrder.deliv_location;
    },
    distance() {
      let pos1 = this.delivOrder && this.delivOrder.deliv_location;
      if (typeof pos1 == "string") {
        pos1 = JSON.parse(pos1);
      }
      const pos2 =
        this.delivOrder &&
        this.delivOrder.customer &&
        this.delivOrder.customer.address &&
        this.delivOrder.customer.address.geometry;
      if (!pos1 || !pos2 || !pos1.lat) return null;

      // ref http://www.movable-type.co.uk/scripts/latlong.html
      // use Equirectangular approximation
      const radius = 6378000; // earth radius in meters
      const phi1 = toRadius(pos1.lat);
      const phi2 = toRadius(pos2.lat);
      const lamda1 = toRadius(pos1.lng);
      const lamda2 = toRadius(pos2.lng);
      const x = (lamda2 - lamda1) * Math.cos((phi1 + phi2) / 2);
      const y = phi2 - phi1;
      let d = Math.sqrt(x * x + y * y) * radius;

      function toRadius(input) {
        return (input * Math.PI) / 180;
      }

      const unit = "mi.";
      if (unit == "mi.") {
        d *= 0.000621371;
      } else {
        d *= 0.001;
      }
      return Math.floor(d * 100) / 100;
    },
  },
  methods: {
    ...mapActions(["updateDriver"]),
    handleOpen(input) {
      this.dialog = true;
      this.delivOrder = input;
      this.loadDriver();
      const initMap = this.initMap;
      setTimeout(() => {
        initMap();
      }, 100);
    },
    async loadDriver() {
      if (!this.driver) return;
      const params = {
        criteria: { _id: this.driver._id },
      };
      const res = await this.axios.post("/driver/accounts/retrieve", params);
      this.updateDriver(res.data);
    },
    async initMap() {
      if (!this.delivOrder) return;
      if (
        this.delivOrder.seller &&
        this.delivOrder.seller.address &&
        this.delivOrder.seller.address.geometry
      ) {
        this.biz_location = this.delivOrder.seller.address.geometry;
      } else {
        const params = {
          criteria: { _id: this.delivOrder.biz },
          select: "address",
        };
        const res = await this.axios.post("/bizs/retrieve", params);
        const biz = res.data;
        this.biz_location = biz.address.geometry;
      }
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: this.center,
        zoom: 13,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false,
      });
      this.addMarker(this.biz_location, "A");
      this.addMarker(this.customer_location, "B");
      if (this.deliv_location) this.addDropOffMark(this.deliv_location);
      else this.addCurrentLocation(this.driver_location);
    },
    addMarker(position, label, icon) {
      if (!position) return;
      // eslint-disable-next-line no-undef
      new google.maps.Marker({
        position: position,
        label: label,
        icon: icon,
        map: this.map,
        clickable: false,
      });
    },
    addDropOffMark(position) {
      if (!position || !position.lat) return;
      // eslint-disable-next-line no-undef
      new google.maps.Marker({
        position: position,
        icon: {
          url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
        },
        map: this.map,
        clickable: false,
      });
    },
    addCurrentLocation(position) {
      if (!position || !position.lat) return;
      // eslint-disable-next-line no-undef
      new google.maps.Marker({
        position: position,
        icon: {
          url: "https://maps.google.com/mapfiles/ms/icons/purple-dot.png",
        },
        map: this.map,
        clickable: false,
      });
    },
  },
  mounted() {
    EventBus.$on("show-deliv-order-map", this.handleOpen);
  },
  destroyed() {
    EventBus.$off("show-deliv-order-map");
  },
};
</script>

<style scoped>
#map {
  height: 400px;
  background: gray;
}
</style>