<template lang="pug">
div
  v-card
    v-toolbar(flat color='teal darken-2' dense dark)
      v-toolbar-title.body-2 Weekly Schedule
      v-spacer
      v-btn(icon @click='open')
        v-icon(small).fa.fa-pen
    v-list(dense v-if='deliv')
      v-list-item(v-for='item in deliv.schedule.weekdays' :key='item._id')
        v-list-item-content {{ item.date | weekday }}
        v-list-item-content.align-end
          span(v-if='item.status') {{ item.schedule | dayschedule }}
          span(v-else) Closed
  v-dialog(v-model='dialog' width="500")
    v-card
      v-form(@submit.prevent='submit')
        v-container
          v-layout(row v-for='(item, windex) in weekdays' :key='windex')
            v-checkbox(v-model='item.status' :label="weekday(item.date)" hide-details style='width: 130px;')
            v-flex(v-if='item.status')
              v-layout(column)
                v-layout(row v-for='(s, sindex) in item.schedule' :key='sindex' align-baseline)
                  v-flex(xs4)
                    v-select(v-model='item.schedule[sindex].range[0]' :items='items' menu-props='auto' dense hide-details single-line)
                  v-flex(xs4)
                    v-select(v-model='item.schedule[sindex].range[1]' :items='items' menu-props='auto' dense hide-details single-line)
                  v-flex(xs4)
                    v-icon(small color='red' v-if='item.schedule.length>1' @click='removeRange(windex, sindex)').fa.fa-times.mx-2
                    v-icon(small color='secondary' v-if='sindex===item.schedule.length-1' @click='addRange(windex)').fa.fa-plus.mx-2
          v-btn(block color="secondary" type='submit').mt-3 Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      items: [],
      weekdays: []
    };
  },
  computed: {
    ...mapGetters(["deliv"])
  },
  methods: {
    ...mapActions(["setDeliv"]),
    weekday(date) {
      return this.$options.filters.weekdayShort(date);
    },
    setItems() {
      const minutesToTime = this.$options.filters.minutesToTime;
      this.items = _.map(_.range(0, 1470, 30), o => {
        return {
          value: o,
          text: minutesToTime(o)
        };
      });
    },
    open() {
      if (!this.deliv) return;
      this.dialog = true;
      this.weekdays = JSON.parse(JSON.stringify(this.deliv.schedule.weekdays));
      /// put sunday to end
      if (
        this.weekdays &&
        this.weekdays.length &&
        this.weekdays[0].date === 0
      ) {
        const sunday = this.weekdays.shift();
        this.weekdays.push(sunday);
      }
    },
    addRange(windex) {
      if (windex < this.weekdays.length) {
        this.weekdays[windex].schedule.push({ range: [480, 1200] });
      }
    },
    removeRange(windex, sindex) {
      if (windex < this.weekdays.length) {
        if (sindex < this.weekdays[windex].schedule.length) {
          this.weekdays[windex].schedule.splice(sindex, 1);
        }
      }
    },
    submit() {
      if (!this.deliv) return;
      const found = _.find(this.weekdays, o => {
        const incorrectRange = _.find(o.schedule, s => {
          return s.range.length !== 2 || s.range[0] >= s.range[1];
        });
        return incorrectRange !== undefined && o.status;
      });
      if (found) {
        const date = this.$options.filters.weekday(found.date);
        alert("Closing time should be greater than opening time: " + date);
        return;
      }
      this.dialog = false;
      const options = {
        criteria: { _id: this.deliv._id },
        action: { "schedule.weekdays": this.weekdays }
      };
      this.axios.post("/deliv/update", options).then(response => {
        this.setDeliv(response.data);
      });
    }
  },
  mounted() {
    this.setItems();
  }
};
</script>
