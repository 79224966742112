<template lang="pug">
v-simple-table(dense)
  thead
    tr
      th Created
      th Name
      th Period
      th # Orders
      th Base
      th Tip
      th Deduct
      th Total
      th
  tbody
    tr(v-for="(item, index) in driverPayouts", :key="index")
      td {{ item.created | datetime('M/D') }}
      td
        span(v-if="item.name") {{ item.name }}
      td {{ item.begin | datetime('M/D') }} - {{ item.end | datetime('M/D') }}
      td {{ item.count }}
      td {{ item.fee | currency }}
      td {{ item.tip | currency }}
      td {{ -item.deduct | currency }}
      td {{ item.amount | currency }}
      td
        v-menu(offset-y)
          template(v-slot:activator="{ on }")
            v-btn(icon, small, v-on.stop="on")
              v-icon.fa.fa-ellipsis-h(small, color="secondary")
          v-list(dense)
            ShowOrders(:payout="item")
    tr
      td Summary
      td
      td {{ driverPayouts.length }}
      td {{ sum.count }}
      td {{ sum.fee | currency }}
      td {{ sum.tip | currency }}
      td {{ -sum.deduct | currency }}
      td {{ sum.amount | currency }}
  DelivOrders
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import ShowOrders from "./ShowOrders";
import DelivOrders from "./DelivOrders";

export default {
  components: { ShowOrders, DelivOrders },
  computed: {
    ...mapGetters(["driverPayouts"]),
    sum() {
      return {
        count: _.reduce(
          this.driverPayouts,
          (memo, item) => {
            return memo + item.count;
          },
          0
        ),
        fee: _.reduce(
          this.driverPayouts,
          (memo, item) => {
            return memo + item.fee;
          },
          0
        ),
        tip: _.reduce(
          this.driverPayouts,
          (memo, item) => {
            return memo + item.tip;
          },
          0
        ),
        deduct: _.reduce(
          this.driverPayouts,
          (memo, item) => {
            return memo + item.deduct;
          },
          0
        ),
        amount: _.reduce(
          this.driverPayouts,
          (memo, item) => {
            return memo + item.amount;
          },
          0
        ),
      };
    },
  },
};
</script>
