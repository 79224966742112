import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTextField,{attrs:{"solo":"","dense":"","label":"Search","placeholder":"Search","append-icon":"search","color":"secondary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.drivers,"sort-by":"name","search":_vm.search,"mobile-breakpoint":"0","dense":"","role":"button"},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("phone")(item.phone)))])]}},{key:"item.alcohol_license.status",fn:function(ref){
var item = ref.item;
return [(item.alcohol_license.status)?_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("mdi-glass-cocktail")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }