import _ from "underscore";

const state = {
  persons: []
};

const getters = {
  persons: state => state.persons
};

const actions = {
  setPersons: ({
    commit
  }, data) => {
    commit("setPersons", data);
  },
  updatePerson: ({
    commit
  }, data) => {
    commit("updatePerson", data);
  },
  addPerson: ({
    commit
  }, data) => {
    commit("addPerson", data);
  },
  removePerson: ({
    commit
  }, data) => {
    commit("removePerson", data);
  }
};

const mutations = {
  setPersons(state, data) {
    state.persons = data;
  },
  updatePerson(state, data) {
    state.persons = _.map(state.persons, o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addPerson(state, data) {
    state.persons.push(data);
  },
  removePerson(state, data) {
    state.persons = _.reject(state.persons, o => {
      return o._id === data._id;
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};