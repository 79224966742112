<template lang="pug">
v-dialog(v-model='dialog' width="600")
  v-card
    v-card-text
      .sl-title Delivery Orders
      v-simple-table(dense )
        thead
          tr
            th Date
            th Base
            th Tip
            th Total
            th Driver
            //- th ID
        tbody
          tr(v-for='(item, index) in delivOrders' :key='index')
            td {{ item.needed | date }}
            td {{ item.fee | currency }}
            td {{ item.tip | currency }}
            td {{ item.fee + item.tip | currency }}
            td
              span(v-if='item.driver') {{ item.driver.name }}
              //- v-btn(@click='recreate(item)' icon small)
              //-   v-icon(small) mdi-account-plus
            //- td {{ item._id }}
          tr
            td Sum
            td {{ sum.fee | currency }}
            td {{ sum.tip | currency }}
            td {{ sum.fee + sum.tip | currency }}
            td
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["delivOrders"]),
    sum() {
      return {
        fee: _.reduce(
          this.delivOrders,
          (memo, item) => {
            return memo + item.fee;
          },
          0
        ),
        tip: _.reduce(
          this.delivOrders,
          (memo, item) => {
            return memo + item.tip;
          },
          0
        ),
      };
    },
  },
  methods: {
    ...mapActions(["setDelivOrders"]),
    recreate(order) {
      if (!order) return;
      const data = {
        delivOrderId: order._id,
      };
      this.axios
        .post("/driver/accounts/recreate", data)
        .then(() => {
          alert("Success");
        })
        .catch(err => {
          if (err && err.response) alert(err.response.data);
        });
    },
    show(ids) {
      if (!ids) return;
      this.dialog = true;
      const options = {
        criteria: {
          _id: { $in: ids },
        },
      };
      this.axios.post("/delivOrders/list", options).then(response => {
        const data = _.sortBy(response.data, "needed");
        this.setDelivOrders(data);
        this.loading = false;
      });
    },
  },
  mounted() {
    EventBus.$on("show-delivorders", this.show);
  },
  destroyed() {
    EventBus.$off("show-delivorders");
  },
};
</script>
