<template lang="pug">
v-dialog(v-model="dialog", max-width="800", scrollable)
  v-card(v-if="delivOrder")
    v-toolbar(dense, flat)
      .subtitle-2.text-truncate.pr-3 {{ delivOrder.bizName }}
    v-card-text
      Customer(:delivOrder="delivOrder")
      v-text-field(label="Reason", v-model="reason")
      DriverTable(
        @select="selectDriver",
        :selected="selected",
        :grouped_drivers="grouped_drivers",
        :delivOrder="delivOrder"
      )
    v-card-actions
      v-btn(
        color="secondary",
        v-if="selected",
        @click="transfer()",
        block,
        :loading="loading"
      ) Transfer to {{ selectedName }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import moment from "moment";
import Customer from "./../List/Cell/Customer";
import DriverTable from "./../AssignDriver/DriverTable";

export default {
  components: { DriverTable, Customer },
  data() {
    return {
      dialog: false,
      reason: "",
      selected: null,
      selectedName: "",
      delivOrder: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["drivers", "driverSchedules", "deliv"]),
    current_driver() {
      if (
        this.delivOrder &&
        this.delivOrder.driver &&
        this.delivOrder.driver.id
      ) {
        return _.find(this.drivers, (o) => o._id == this.delivOrder.driver.id);
      }
      return null;
    },
    grouped_drivers() {
      if (!this.delivOrder) return [];
      const needed = moment(this.delivOrder.needed);
      const minutes =
        needed.hours() * 60 + Math.floor(needed.minutes() / 30) * 30;
      const schedules = _.filter(this.driverSchedules, (o) => {
        if (!o.status) return false;
        const block = _.find(o.blocks, (b) => b.mm == minutes);
        if (block) return block.status;
        else return false;
      });
      const users = _.pluck(schedules, "user");
      let working_group = [];
      let unknown_group = [];
      _.each(this.drivers, (o) => {
        if (users.indexOf(o.user) != -1) working_group.push(o);
        else unknown_group.push(o);
      });
      return {
        working_group: _.sortBy(working_group, "name"),
        unknown_group: _.sortBy(unknown_group, "name"),
      };
    },
  },
  methods: {
    ...mapActions(["updateDelivOrder", "setDriverSchedules", "setDrivers"]),
    selectDriver(driver) {
      this.selected = driver._id;
      this.selectedName = driver.name;
    },
    load(data) {
      if (!data) return;
      this.selected = null;
      this.delivOrder = data;
      this.reason = "";
      this.dialog = true;
      this.loadDrivers();
    },
    async loadDrivers() {
      if (!this.deliv || !this.deliv._id) return;
      const params = {
        criteria: { deliv: this.deliv._id, status: { $ne: false } },
      };
      const res = await this.axios.post("/driver/accounts/list", params);
      this.setDrivers(res.data);
    },
    async transfer() {
      if (!this.delivOrder || !this.selected) {
        alert("Please select first");
        return;
      }
      this.loading = true;
      const params = {
        delivOrderId: this.delivOrder._id,
        driverId: this.selected,
        reason: this.reason,
      };
      try {
        const res = await this.axios.post(
          "/delivOrders/transferDriver",
          params
        );
        this.updateDelivOrder(res.data);
        this.selected = null;
        this.dialog = false;
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
      this.loading = false;
    },
  },
  mounted() {
    EventBus.$on("transfer-driver", this.load);
  },
  destroyed() {
    EventBus.$off("transfer-driver");
  },
};
</script>
