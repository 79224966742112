<template lang="pug">
.d-flex.flex-row.justify-center.my-10
  v-card(width="500", max-width="100%")
    v-toolbar(flat, dense)
      .subtitle-2 Set up a delivery company
    v-card-text
      v-form(@submit.prevent="submit")
        v-row(dense, wrap)
          v-col(cols="8")
            v-text-field(
              label="Company Name",
              v-model="name",
              :error-messages="nameErrors",
              required,
              @blur="$v.name.$touch()",
              autofocus
            )
          v-col(cols="4")
            v-text-field(
              label="Phone",
              v-model="phone",
              v-mask="\"(###) ###-####\"",
              :error-messages="phoneErrors",
              @blur="$v.phone.$touch()"
            )
          v-col(cols="8")
            v-text-field(
              v-model="line1",
              :error-messages="line1Errors",
              @blur="$v.line1.$touch()"
            )
          v-col(cols="4")
            v-text-field(
              label="Suite Room",
              v-model="line2",
              :error-messages="line2Errors",
              @blur="$v.line2.$touch()",
              hint="Optional",
              persistent-hint
            )
          v-col(cols="6")
            v-select(
              label="Country",
              name="state",
              v-model="country",
              :items="countriesStates",
              item-text="name",
              item-value="abbreviation"
            )
          v-col(cols="6")
            v-select(
              label="State",
              name="state",
              v-model="state",
              :items="stateItems",
              item-text="name",
              item-value="abbreviation",
              :error-messages="stateErrors",
              @blur="$v.address.state.$touch()"
            )
          v-col(cols="6")
            v-text-field(
              label="City",
              v-model="city",
              :error-messages="cityErrors",
              @blur="$v.city.$touch()"
            )
          v-col(cols="6")
            v-text-field(
              label="Zipcode",
              v-model="postal_code",
              :error-messages="postal_codeErrors",
              @blur="$v.postal_code.$touch()"
            )
        v-btn.text-capitalize(
          block,
          color="secondary",
          type="submit",
          :disabled="$v.$invalid",
          :loading="loading"
        ) Create a Delivery Business
        .pl-2.red--text(v-for="(error, index) in errors", :key="index + 'index'") {{ error }}
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import MapUtils from "@/utils/map.js";
import _ from "underscore";

export default {
  mixins: [validationMixin],
  validations: {
    name: { required, maxLength: maxLength(40) },
    phone: { required },
    line1: { required, maxLength: maxLength(40) },
    line2: { maxLength: maxLength(15) },
    postal_code: { required },
    city: { required },
    state: { required },
  },
  data() {
    return {
      name: "",
      phone: "",
      line1: "",
      line2: "",
      postal_code: "",
      city: "",
      state: "",
      country: "US",
      countriesStates: [],
      loading: false,
      errors: null,
    };
  },
  computed: {
    stateItems() {
      if (!this.countriesStates || !this.country) return [];
      const country = _.find(
        this.countriesStates,
        (o) => o.abbreviation == this.country
      );
      if (!country) return [];
      return country.states;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Required");
      !this.$v.name.maxLength &&
        errors.push("Should be less than 40 charactors");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push("A phone is required");
      return errors;
    },
    line1Errors() {
      const errors = [];
      if (!this.$v.line1.$dirty) return errors;
      !this.$v.line1.required && errors.push("Required");
      !this.$v.line1.maxLength &&
        errors.push("Should be less than 40 charactors");
      return errors;
    },
    line2Errors() {
      const errors = [];
      if (!this.$v.line2.$dirty) return errors;
      !this.$v.line2.maxLength &&
        errors.push("Should be less than 15 charactors");
      return errors;
    },
    postal_codeErrors() {
      const errors = [];
      if (!this.$v.postal_code.$dirty) return errors;
      !this.$v.postal_code.required && errors.push("Required");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("Required");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push("Required");
      return errors;
    },
  },
  methods: {
    processAddress() {
      const data = {
        line1: this.line1,
        line2: this.line2,
        postal_code: this.postal_code,
        city: this.city,
        state: this.state,
        country: this.country,
      };
      return new Promise((resolve, reject) => {
        MapUtils.getGeoCode(data)
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.results &&
              response.data.results.length
            ) {
              data.geometry = response.data.results[0].geometry.location;
              MapUtils.getTimeZone(data.geometry.lat, data.geometry.lng)
                .then((response) => {
                  data.timezone = response.data.timeZoneId;
                  resolve(data);
                })
                .catch(() => {
                  this.errors = [
                    "Can't find the correct timezone for the input address",
                  ];
                  reject(null);
                });
            } else {
              this.errors = [
                "Can't find the correct geo location for the input address",
              ];
              reject(null);
            }
          })
          .catch(() => {
            this.errors = [
              "Can't find the correct geo location for the input address",
            ];
            reject(null);
          });
      });
    },
    async submit() {
      this.loading = true;
      const address = await this.processAddress();
      const options = {
        name: this.name.trim(),
        phone: this.phone.replace(/[^\d]/g, ""),
        address: address,
      };
      this.axios
        .post("/deliv/create", options)
        .then(() => {
          this.loading = false;
          this.$router.push("/");
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) alert(err.response.data || "Failed");
        });
      this.loading = false;
    },
  },
  mounted() {
    MapUtils.loadCountriesStates().then((data) => {
      this.countriesStates = data;
    });
  },
};
</script>
