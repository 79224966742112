<template lang="pug">
span(v-if="score") {{ score | number(0) }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  props: ["driverId"],
  computed: {
    ...mapGetters(["drivers", "delivOrders"]),
    driver() {
      return _.find(this.drivers, (o) => o._id == this.driverId);
    },
    /// current score
    score() {
      if (!this.delivOrders || !this.delivOrders.length) return 0;
      const qualified = _.filter(this.delivOrders, (o) => {
        return (
          o.ontime_score != null && o.type == 0 && o.driver.id == this.driverId
        );
      });
      if (!qualified.length) return 0;
      const total = _.reduce(
        qualified,
        (memo, item) => {
          return memo + item.ontime_score;
        },
        0
      );
      const count = qualified.length;
      return (total / count) * 20;
    },
  },
};
</script>