import Accounts from './Accounts/index.vue'
import Hire from './Hire/index.vue'
import Payouts from './Payouts/index.vue'
import Schedules from './Schedules/index.vue'

export default [{
  path: '/driver/accounts',
  component: Accounts,
  meta: {
    auth: true,
    role_manager: true
  }
}, {
  path: '/driver/hire',
  component: Hire,
  meta: {
    auth: true
  }
}, {
  path: '/driver/payouts',
  component: Payouts,
  meta: {
    auth: true
  }
}, {
  path: '/driver/schedules',
  component: Schedules,
  meta: {
    auth: true
  }
}]