import _ from 'underscore'

const state = {
  delivOrders: []
}

const getters = {
  delivOrders: state => state.delivOrders
}

const actions = {
  setDelivOrders: ({
    commit
  }, delivOrders) => {
    commit('setDelivOrders', delivOrders)
  },
  updateDelivOrder: ({
    commit
  }, data) => {
    commit('updateDelivOrder', data)
  },
  addDelivOrder: ({
    commit
  }, data) => {
    commit("addDelivOrder", data);
  },
  removeDelivOrder: ({
    commit
  }, data) => {
    commit("removeDelivOrder", data);
  }
}

const mutations = {
  setDelivOrders(state, delivOrders) {
    state.delivOrders = delivOrders
  },
  updateDelivOrder(state, data) {
    state.delivOrders = _.map(state.delivOrders, o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addDelivOrder(state, data) {
    state.delivOrders.push(data);
  },
  removeDelivOrder(state, data) {
    state.delivOrders = _.reject(state.delivOrders, o => o._id === data._id);
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}