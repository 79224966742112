<template lang="pug">
v-dialog(v-model="dialog", width="300")
  v-card(v-if="driver")
    v-card-text
      .sl-title Hire {{ driver.name }}
      v-form(@submit.prevent="save")
        v-select(v-model="stage", label="Level", :items="stage_items")
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "HireDriver",
  data() {
    return {
      dialog: false,
      loading: false,
      driver: null,
      stage: 1,
      stage_items: [
        { text: "Regular", value: 1 },
        { text: "Catering", value: 2 },
      ],
    };
  },
  computed: {
    ...mapGetters(["deliv"]),
  },
  methods: {
    ...mapActions(["updateDriver"]),
    open(data) {
      if (!data) return;
      this.driver = data;
      this.state = 1;
      this.dialog = true;
    },
    async save() {
      if (!this.driver || !this.deliv) return;
      this.loading = true;
      const params = {
        driverId: this.driver._id,
        delivId: this.deliv._id,
        stage: this.stage,
      };
      try {
        const res = await this.axios.post("/driver/accounts/hire", params);
        this.updateDriver(res.data);
        this.dialog = false;
      } catch (err) {
        alert(err.response.data);
      }
      this.loading = false;
    },
  },
  mounted() {
    EventBus.$on("hire-driver", this.open);
  },
  destroyed() {
    EventBus.$off("hire-driver");
  },
};
</script>
