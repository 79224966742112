<template lang="pug">
div
  v-row.mb-3(justify="center")
    v-col(cols="10", md="4")
      v-text-field(
        dense,
        v-model="search",
        clearable,
        append-icon="search",
        label="Search Business",
        solo,
        hide-details
      )
  v-data-table(
    :headers="headers",
    :items="bizs",
    sort-by="name",
    :search="search",
    dense
  )
    template(v-slot:item.phones="{ item }")
      span(v-if="item.phones && item.phones.length") {{ item.phones[0].number | phone }}
    template(v-slot:item.address="{ item }")
      span {{ item.address | address }}
</template>

<script>
export default {
  props: ["bizs"],
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phones", sortable: false },
        { text: "Address", value: "address", sortable: false },
      ],
      search: "",
    };
  },
};
</script>
