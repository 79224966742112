<template lang="pug">
v-simple-table(dense)
  thead
    tr
      th Date
      th #
      th.text-right Total
      th.text-right Average
  tbody
    tr(v-for="(item, index) in days", :key="index")
      td {{ item.date }}
      td {{ item.count }}
      td.text-right {{ item.total | currency }}
      td.text-right {{ item.average | currency }}
</template>

<script>
import _ from "underscore";
import moment from "moment";

export default {
  props: ["list"],
  computed: {
    days() {
      if (!this.list) return [];
      const data = _.chain(this.list)
        .map((o) => {
          return _.extend(o, {
            date: moment(o.needed).format("ddd M/D"),
          });
        })
        .groupBy((o) => o.date)
        .map((o, k) => {
          const fee = _.reduce(
            o,
            (memo, item) => {
              return memo + item.fee;
            },
            0
          );
          const tip = _.reduce(
            o,
            (memo, item) => {
              return memo + item.tip;
            },
            0
          );
          return {
            date: k,
            fee: fee,
            tip: tip,
            total: fee + tip,
            count: o.length,
            average: (fee + tip) / o.length,
          };
        })
        .value();
      return data;
    },
  },
};
</script>
