import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"my-3",attrs:{"outlined":""}},[_c(VCardText,[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c('div',{staticClass:"caption sl-secondary-text"},[_vm._v("Basic")]),_c('Edit')],1),_c(VDivider,{staticClass:"mt-2"})],1),(_vm.deliv)?_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Phone")])],1),_c(VListItemContent,{staticClass:"align-end"},[_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("phone")(_vm.deliv.phone)))])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Email")])],1),_c(VListItemContent,{staticClass:"align-end"},[_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(_vm._s(_vm.deliv.email))])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("CC Recipients")])],1),_c(VListItemContent,{staticClass:"align-end"},[_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(_vm._s(_vm.deliv.carboncopy))])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Serving States")])],1),_c(VListItemContent,{staticClass:"align-end"},[_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(_vm._s(_vm.states))])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }