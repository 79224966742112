<template lang="pug">
v-container(v-if="!loading && $auth.check()")
  Table(v-if="has_company")
  .my-10.text-center.subtitle-2(v-else) Thanks for visiting. You don't have access to any delivery service.
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Table from "./Table";
import _ from "underscore";

export default {
  components: { Table },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["delivUserList", "deliv"]),
    has_company() {
      return this.delivUserList && this.delivUserList.length;
    },
  },
  methods: {
    ...mapActions(["SetDelivUserList", "setDelivs"]),
    async load() {
      if (!this.$auth.check()) return;
      this.loading = true;
      const user = this.$auth.user();
      const params = { criteria: { user: user._id } };
      let items = [];
      try {
        const res = await this.axios.post("/persons/list", params);
        items = res.data;
      } catch (err) {
        //
      }
      const delivs = await this.loadDelivs(_.pluck(items, "deliv"));
      this.setDelivs(delivs);
      this.SetDelivUserList(items);
      this.loading = false;
    },
    async loadDelivs(ids) {
      if (!ids || !ids.length) return;
      const params = { criteria: { _id: { $in: ids } } };
      try {
        const res = await this.axios.post("/deliv/list", params);
        return res.data;
      } catch (err) {
        return [];
      }
    },
  },
  mounted() {
    this.load();
    EventBus.$on("login", this.load());
  },
  destroyed() {
    EventBus.$off("login");
  },
};
</script>
