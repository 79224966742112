<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  Form(:bizs="bizs", :dialog="dialog", @saved="dialog = false")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Form from "./Form";
import { mapGetters } from "vuex";

export default {
  components: { Form },
  data() {
    return {
      dialog: false,
      bizs: [],
    };
  },
  computed: {
    ...mapGetters(["deliv"]),
  },
  methods: {
    open() {
      this.dialog = true;
    },
    async load() {
      if (!this.deliv) return;
      const params = { delivId: this.deliv._id };
      const res = await this.axios.post("/delivbiz/listBizsByDeliv", params);
      this.bizs = res.data;
    },
  },
  mounted() {
    this.load();
    EventBus.$on("create-manager-order", this.open);
  },
  destroyed() {
    EventBus.$off("create-manager-order");
  },
};
</script>
