<template lang="pug">
v-btn(text @click='populate()' v-if='!person.email') Populate
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["person"],
  methods: {
    ...mapActions(["updatePerson"]),
    populate() {
      if (!this.person) return;
      const data = {
        personId: this.person._id
      };
      this.axios
        .post("/persons/populateUser", data)
        .then(response => {
          this.updatePerson(response.data);
        })
        .catch(err => {
          if (err.response) alert(err.response.data);
        });
    }
  }
};
</script>
