<template lang="pug">
v-row(wrap, dense)
  v-col(
    cols="12",
    sm="6",
    md="3",
    v-for="(item, index) in items",
    :key="index"
  )
    v-card
      v-card-text
        .d-flex.flex-row.align-center.justify-space-between
          v-icon(small) mdi-account
          span {{ item.name }}
        .d-flex.flex-row.align-center.justify-space-between
          v-icon(small) mdi-phone
          span {{ item.phone | phone }}
        .d-flex.flex-row.align-center.justify-space-between
          v-icon(small) mdi-email
          span {{ item.email }}
        .d-flex.flex-row.align-center.justify-space-between
          v-icon(small) mdi-map
          div
            span.ml-2(v-for="(zone, izone) in item.zones", :key="izone + 'zone'") {{ zone }}
        .d-flex.flex-row.align-center.justify-space-between
          v-spacer
          span {{ item.created | date }}
      v-card-actions
        v-spacer
        v-btn.text-capitalize(
          color="secondary",
          rounded,
          @click="handleHire(item)"
        ) Hire
        v-btn.text-capitalize(
          color="error",
          rounded,
          @click="handleReject(item)"
        ) Reject
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "", value: "action", sortable: false },
      ],
      search: "",
    };
  },
  computed: {
    ...mapGetters(["drivers", "deliv"]),
    items() {
      return _.filter(this.drivers, (o) => o.stage == 0);
    },
  },
  methods: {
    ...mapActions(["updateDriver"]),
    handleReject(driver) {
      EventBus.$emit("reject-driver", driver);
    },
    async handleHire(driver) {
      EventBus.$emit("hire-driver", driver);
    },
  },
};
</script>
