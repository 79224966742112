<template lang="pug">
v-card.my-3(outlined)
  v-card-text
    .d-flex.flex-row.align-center.justify-space-between
      .caption.sl-secondary-text Address
      v-btn.text-capitalize(@click="open()", outlined, small, depressed, color="grey darken-2") Edit
        v-icon(right, small) mdi-pencil
    v-divider.mt-2
  v-list(dense, v-if="deliv")
    v-list-item
      v-list-item-content
        v-list-item-title {{ deliv.address | address }}
  v-dialog(v-model="dialog", width="500")
    AddressForm(:address="address", @save="save")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddressForm from "@/components/basic/AddressForm";

export default {
  name: "DelivAddress",
  components: { AddressForm },
  data() {
    return {
      dialog: false,
      address: {},
    };
  },
  computed: {
    ...mapGetters(["deliv"]),
  },
  methods: {
    ...mapActions(["setDeliv"]),
    open() {
      this.address = JSON.parse(JSON.stringify(this.deliv.address));
      this.dialog = true;
    },
    async save(address) {
      if (!this.deliv || !this.deliv._id) return;
      const options = {
        criteria: { _id: this.deliv._id },
        action: { $set: { address: address } },
      };
      const res = await this.axios.post("/deliv/update", options);
      this.dialog = false;
      this.setDeliv(res.data);
    },
  },
};
</script>
