import _ from "underscore";

const state = {
  drivers: [],
  recentDrivers: []
};

const getters = {
  drivers: state => state.drivers,
  recentDrivers: state => state.recentDrivers
};

const actions = {
  setDrivers: ({
    commit
  }, data) => {
    commit("setDrivers", data);
  },
  updateDriver: ({
    commit
  }, data) => {
    commit("updateDriver", data);
  },
  addDriver: ({
    commit
  }, data) => {
    commit("addDriver", data);
  },
  removeDriver: ({
    commit
  }, data) => {
    commit("removeDriver", data);
  },
  addRecentDriver: ({
    commit
  }, data) => {
    commit("addRecentDriver", data);
  },
};

const mutations = {
  setDrivers(state, data) {
    state.drivers = data;
  },
  updateDriver(state, data) {
    state.drivers = _.map(state.drivers, o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addDriver(state, data) {
    state.drivers.push(data);
  },
  removeDriver(state, data) {
    state.drivers = _.reject(state.drivers, o => {
      return o._id === data._id;
    });
  },
  addRecentDriver(data) {
    state.recentDrivers = _.reject(state.recentDrivers, o => {
      return o._id === data._id;
    })
    state.recentDrivers.unshift(data)
    state.recentDrivers = state.recentDrivers.slice(1, 5)
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};