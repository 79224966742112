import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.open()}}},[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("mdi-pencil")])],1),_c(VDialog,{attrs:{"width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardText,[_c('div',{staticClass:"sl-title"},[_vm._v("Edit User & Access")]),_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],attrs:{"label":"Phone"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),(!_vm.isMe)?_c(VSelect,{attrs:{"items":_vm.role_items,"label":"Role"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}):_vm._e(),_c(VBtn,{attrs:{"block":"","color":"secondary","type":"submit"}},[_vm._v("Save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }