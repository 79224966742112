<template lang="pug">
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  computed: {
    ...mapGetters(["deliv"]),
  },
  methods: {
    ...mapActions(["setBizs"]),
    load() {
      if (!this.deliv) return;
      const options = {
        criteria: { "orderType.delivery.deliv": this.deliv._id },
        select: "name address phones email",
      };
      this.axios.post("/bizs/list", options).then(response => {
        this.setBizs(response.data);
      });
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-deliv", this.load);
  },
  destroyed() {
    EventBus.$off("switch-deliv");
  },
};
</script>
