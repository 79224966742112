<template lang="pug">
div
  Summary(:bizs="bizs")
  Table(:bizs="bizs")
</template>

<script>
import { mapGetters } from "vuex";
import Summary from "./../Share/Summary";
import Table from "./Table";

export default {
  components: { Summary, Table },
  computed: {
    ...mapGetters(["bizs"]),
  },
};
</script>
