<template lang="pug">
.d-flex.flex-row.align-center.subtitle-2
  v-icon.mr-2(small, v-if="isSelflane") $selflane_rev
  span {{ delivOrder.needed | time }}
  span.ml-2.error--text(v-if="late") Late!!!
  v-chip.ml-2(small, dark, color="secondary", v-if="hasAlcohol") Alcohol
  v-spacer
  v-menu(offset-y)
    template(v-slot:activator="{ on }")
      v-btn(icon, v-on="on")
        v-icon(color="secondary") mdi-dots-horizontal
    v-list.py-0(dense)
      v-list-item(@click="show()", v-if="isSelflane") Order Details
      v-list-item.red--text(@click="handleCancel()", v-else) Cancel
      v-list-item(@click="handleTransfer()", v-if="canTransfer") Transfer Driver
      v-list-item(@click="handleRedeliver()", v-if="canRedeliver") Redeliver
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import moment from "moment";

export default {
  props: ["delivOrder"],
  computed: {
    isSelflane() {
      return this.delivOrder && this.delivOrder.order;
    },
    late() {
      if (!this.isSelflane) return false;
      if (this.delivOrder.status != 4) {
        if (this.delivOrder.needed < Date.now()) return true;
      }
      return false;
    },
    hasAlcohol() {
      const found = _.find(this.delivOrder.summary, (o) => {
        return o.classification == 3;
      });
      return found && found.amount > 0;
    },
    canTransfer() {
      const driver = this.delivOrder && this.delivOrder.driver;
      return driver && driver.accept && driver.accept > 0;
    },
    canRedeliver() {
      const todayBegin = moment().startOf("day").unix() * 1000;
      const todayEnd = moment().endOf("day").unix() * 1000;
      if (this.delivOrder.needed < todayBegin || this.delivOrder > todayEnd)
        return false;
      return (
        this.delivOrder.type == 0 &&
        this.delivOrder &&
        this.delivOrder.driver &&
        this.delivOrder.driver.id &&
        this.delivOrder.pickupTime > 0
      );
    },
  },
  methods: {
    ...mapActions(["removeDelivOrder"]),
    show() {
      if (this.delivOrder && this.delivOrder.order) {
        EventBus.$emit("show-order-detail", this.delivOrder.order);
      }
    },
    handleCancel() {
      confirm("Do you want to cancel this delivery?") && this.cancel();
    },
    async cancel() {
      if (!this.delivOrder) return;
      const params = { id: this.delivOrder._id };
      await this.axios.post("/delivOrders/cancelByDeliv", params);
      this.removeDelivOrder(this.delivOrder);
    },
    handleTransfer() {
      EventBus.$emit("transfer-driver", this.delivOrder);
    },
    handleRedeliver() {
      EventBus.$emit("redeliver-order", this.delivOrder);
    },
  },
};
</script>
