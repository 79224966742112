import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.delivOrder)?_c(VCard,[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"subtitle-2 text-truncate pr-3"},[_vm._v(_vm._s(_vm.delivOrder.bizName))])]),_c(VCardText,[_c('Customer',{attrs:{"delivOrder":_vm.delivOrder}}),_c(VTextField,{attrs:{"label":"Reason"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('DriverTable',{attrs:{"selected":_vm.selected,"grouped_drivers":_vm.grouped_drivers,"delivOrder":_vm.delivOrder},on:{"select":_vm.selectDriver}})],1),_c(VCardActions,[(_vm.selected)?_c(VBtn,{attrs:{"color":"secondary","block":"","loading":_vm.loading},on:{"click":function($event){return _vm.transfer()}}},[_vm._v("Transfer to "+_vm._s(_vm.selectedName))]):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }