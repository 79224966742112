<template lang="pug">
v-simple-table(dense)
  thead
    tr
      th Name
      th Status
      th.text-right Score
      th.text-right Mile
  tbody
    tr(
      v-for="(item, windex) in grouped_drivers.working_group",
      :key="windex + 'working'",
      @click="select(item)",
      role="button",
      :class="row_color_class(item)"
    )
      td.text-truncate
        v-icon.mr-1(color="secondary", v-if="item._id == selected", small) mdi-check-circle
        span {{ item.name }}
        v-icon.ml-1(
          color="secondary",
          v-if="item.alcohol_license && item.alcohol_license.status",
          small
        ) mdi-glass-cocktail
      td
        DriverOrderCount(:driverId="item._id")
      td.text-right
        DriverScore(:driverId="item._id")
      td.text-right
        span(v-if="distance(item)") {{ distance(item) | number(1) }}
    tr
      th Not Scheduled
      th
      th
      th
    tr(
      v-for="(item, index) in grouped_drivers.unknown_group",
      :key="index + 'unknown'",
      @click="select(item)",
      role="button",
      :class="row_color_class(item)"
    )
      td.text-truncate
        v-icon.mr-1(color="secondary", v-if="item._id == selected", small) mdi-check-circle
        span {{ item.name }}
        v-icon.ml-1(
          color="secondary",
          v-if="item.alcohol_license && item.alcohol_license.status",
          small
        ) mdi-glass-cocktail
      td
        DriverOrderCount(:driverId="item._id")
      td.text-right
        DriverScore(:driverId="item._id")
      td.text-right
        span(v-if="distance(item)") {{ distance(item) | number(1) }}
</template>

<script>
import DriverOrderCount from "./DriverOrderCount";
import DriverScore from "./DriverScore";
import BizUtils from "@/utils/biz.js";

export default {
  props: ["selected", "grouped_drivers", "delivOrder"],
  components: { DriverOrderCount, DriverScore },
  methods: {
    select(item) {
      this.$emit("select", item);
    },
    row_color_class(item) {
      if (item._id == this.selected) return "secondary-text";
      let last20 = Date.now() - 20 * 60 * 1000;
      if (
        this.delivOrder &&
        this.delivOrder.seller &&
        this.delivOrder.seller.address &&
        this.delivOrder.seller.address.geometry &&
        item.last_location &&
        item.last_location.time > last20
      ) {
        const distance = BizUtils.geoDistance(
          item.last_location,
          this.delivOrder.seller.address.geometry
        );
        if (distance < 3) return "success--text";
      }
      return "black--text";
    },
    distance(item) {
      let last20 = Date.now() - 20 * 60 * 1000;
      if (
        this.delivOrder &&
        this.delivOrder.seller &&
        this.delivOrder.seller.address &&
        this.delivOrder.seller.address.geometry &&
        item.last_location &&
        item.last_location.time > last20
      ) {
        const distance = BizUtils.geoDistance(
          item.last_location,
          this.delivOrder.seller.address.geometry
        );
        return distance;
      }
      return 0;
    },
  },
};
</script>