<template lang="pug">
v-card(outlined)
  template(v-for="(item, index) in items")
    v-divider(v-if="index != 0")
    v-list-item(@click="select(item)")
      v-list-item-content
        span(v-if="item.company") {{ item.company.name }}
      v-list-item-icon(v-if="item.deliv == deliv._id")
        v-icon(color="success") mdi-check-circle
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["delivUserList", "deliv", "delivs"]),
    items() {
      return _.map(this.delivUserList, (o) => {
        o.company = _.find(this.delivs, (d) => d._id == o.deliv);
        return o;
      });
    },
  },
  methods: {
    ...mapActions(["setDeliv", "setDelivRole"]),
    select(item) {
      this.setDeliv(item.company);
      this.setDelivRole(item.role);
    },
  },
};
</script>