<template lang="pug">
v-card.body-2.pa-2(v-if="delivOrder", outlined, height="100%")
  TopBar(:delivOrder="delivOrder")
  .d-flex.flex-row.justify-space-between
    .subtitle-2 {{ delivOrder.bizName }}
    .subtitle-2 {{ delivOrder.fee | currency }}+{{ delivOrder.tip | currency }}
  v-divider
  v-row(wrap)
    v-col(cols="9")
      Customer(:delivOrder="delivOrder")
      DelivTime(:order="delivOrder")
    v-col(cols="3")
      Photo(:delivOrder="delivOrder")
    v-col(cols="12")
      .d-flex.flex-row.align-center
        v-icon.mr-3(small) mdi-badge-account-horizontal
        div(v-if="delivOrder.driver")
          span.text-truncate {{ delivOrder.driver.name }}
          v-icon.ml-2(color="success", small, v-if="delivOrder.driver.accept") mdi-check-circle
        v-spacer
        v-btn.text-capitalize.mr-2(
          outlined,
          rounded,
          x-small,
          depressed,
          color="secondary",
          @click="call(delivOrder.driver.phone)",
          v-if="delivOrder.driver && delivOrder.driver.phone"
        ) {{ delivOrder.driver.phone | phone }}
        v-btn.text-capitalize(
          outlined,
          rounded,
          small,
          depressed,
          color="secondary",
          @click="assign()",
          v-if="delivOrder.status < 3"
        ) Edit
          v-icon(right, small) mdi-pencil

  .indigo--text.subtitle-2.text-center.text-truncate {{ delivOrder.bizmsg }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import TopBar from "./TopBar";
import DelivTime from "./DelivTime";
import Customer from "./Customer";
import Photo from "./Photo";

export default {
  props: ["delivOrder"],
  components: { TopBar, DelivTime, Customer, Photo },
  methods: {
    assign() {
      EventBus.$emit("assign-driver", this.delivOrder);
    },
    call(number) {
      window.open("tel:" + number);
    },
  },
};
</script>
