<template lang="pug">
div
  v-text-field(
    v-model="search",
    solo,
    dense,
    label="Search",
    placeholder="Search",
    append-icon="search",
    color="secondary"
  )
  v-data-table(
    :headers="headers",
    :items="drivers",
    sort-by="name",
    :search="search",
    mobile-breakpoint="0",
    dense,
    @click:row="edit",
    role="button"
  )
    template(v-slot:item.phone="{ item }")
      span {{ item.phone | phone }}
    template(v-slot:item.alcohol_license.status="{ item }")
      v-icon(v-if="item.alcohol_license.status", color="secondary") mdi-glass-cocktail
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "Alcohol License", value: "alcohol_license.status" },
        { text: "Zones", value: "zones", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["drivers"]),
  },
  methods: {
    edit(item) {
      EventBus.$emit("edit-driver", item);
    },
  },
};
</script>
