<template lang="pug">
div
  v-navigation-drawer(
    v-model="drawer",
    app,
    fixed,
    v-if="$auth.check()",
    :clipped="$vuetify.breakpoint.lgAndUp",
    width="250"
  )
    v-list(dense, expand, nav)
      div(v-if="deliv")
        v-list-group(
          v-for="item in items",
          v-model="item.active",
          :key="item.title",
          :prepend-icon="item.action",
          no-action
        )
          template(v-slot:activator)
            v-list-item-title {{ item.title }}
          v-list-item(
            v-for="subItem in item.items",
            :key="subItem.title",
            :to="subItem.to"
          )
            v-list-item-content
              v-list-item-title {{ subItem.title }}
      v-list-item(@click="logout")
        v-list-item-content
          v-list-item-title.body-2 Sign Out
          v-list-item-subtitle.caption {{ $auth.user().email }}
        v-list-item-action
          v-icon mdi-logout
  v-app-bar(
    app,
    dark,
    flat,
    color="primary",
    :clipped-left="$vuetify.breakpoint.lgAndUp"
  )
    v-app-bar-nav-icon(@click="drawer = !drawer", v-if="$auth.check()")
    v-btn(icon, @click="toHome()")
      v-icon $selflane
    .subtitle-2 Selflane Delivery
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavGroups from "@/components/basic/NavGroups.json";
import NavGroupsManager from "@/components/basic/NavGroupsManager.json";

export default {
  data() {
    return {
      drawer: null,
    };
  },
  computed: {
    ...mapGetters(["deliv", "delivRole"]),
    items() {
      if (!this.$auth.user() && this.deliv) return [];
      if (this.delivRole == "owner") {
        return JSON.parse(JSON.stringify(NavGroups));
      } else {
        return JSON.parse(JSON.stringify(NavGroupsManager));
      }
    },
  },
  methods: {
    ...mapActions(["SetDelivUserList"]),
    toHome() {
      const currentPath = this.$router.history.current.path;
      if (currentPath != "/") this.$router.push("/");
    },
    logout() {
      this.SetDelivUserList([]);
      const clientId = process.env.VUE_APP_GOOGLE_CLIENT;
      // eslint-disable-next-line
      gapi.load("auth2", initAuth2);
      const finish = this.$auth.logout();
      function initAuth2() {
        // eslint-disable-next-line
        gapi.auth2.init({ client_id: clientId }).then(() => {
          // eslint-disable-next-line
          gapi.auth2.getAuthInstance().signOut();
          finish;
        });
      }
    },
  },
};
</script>
