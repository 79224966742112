<template lang="pug">
v-btn(icon @click='handleRemove()' v-if='!isMe')
  v-icon(color="error") mdi-delete
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DeletePerson",
  props: ["person"],
  computed: {
    isMe() {
      return (
        this.person &&
        this.person.user &&
        this.person.user == this.$auth.user()._id
      );
    }
  },
  methods: {
    ...mapActions(["removePerson"]),
    handleRemove() {
      confirm("Do you want to delete this account?") && this.remove();
    },
    remove() {
      if (!this.person) return;
      const data = {
        personId: this.person._id
      };
      this.axios
        .post("/persons/deleteOne", data)
        .then(() => {
          this.removePerson(this.person);
        })
        .catch(err => {
          if (err.response) alert(err.response.data);
        });
    }
  }
};
</script>
