<template lang="pug">
span {{ new_count }}/{{ inroute_count }}/{{ all_count }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  props: ["driverId"],
  computed: {
    ...mapGetters(["drivers", "delivOrders"]),
    driver() {
      return _.find(this.drivers, (o) => o._id == this.driverId);
    },
    /// count the fresh orders
    new_count() {
      if (!this.driver) return 0;
      return _.filter(this.delivOrders, (o) => {
        return (
          o.driver &&
          o.status >= 0 &&
          o.driver.id == this.driverId &&
          !o.deliverTime &&
          !o.pickupTime
        );
      }).length;
    },
    /// count the in-route orders
    inroute_count() {
      if (!this.driver) return 0;
      return _.filter(this.delivOrders, (o) => {
        return (
          o.driver &&
          o.status >= 0 &&
          o.driver.id == this.driverId &&
          !o.deliverTime &&
          o.pickupTime
        );
      }).length;
    },
    /// count all orders
    all_count() {
      if (!this.driver) return 0;
      return _.filter(
        this.delivOrders,
        (o) => o.driver && o.status >= 0 && o.driver.id == this.driverId
      ).length;
    },
  },
};
</script>