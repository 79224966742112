import { render, staticRenderFns } from "./RestaurantTable.vue?vue&type=template&id=78b4b782&lang=pug&"
import script from "./RestaurantTable.vue?vue&type=script&lang=js&"
export * from "./RestaurantTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports