<template lang="pug">
v-dialog(v-model="dialog", max-width="800", scrollable)
  v-card(v-if="delivOrder")
    v-card-title.pa-0
      v-col
        .d-flex.flex-row.subtitle-2.justify-space-between.align-center.flex-nowrap.pb-2
          .subtitle-2.text-truncate.pr-3 {{ delivOrder.bizName }}
          v-spacer
          v-btn.text-capitalize(
            small,
            rounded,
            outlined,
            color="success",
            @click="loadSchedules()"
          ) Schedules
            v-icon(right, small) mdi-sync
        .d-flex.flex-row.subtitle-2.justify-space-between.align-center.flex-nowrap
          .subtitle-2 {{ delivOrder.zone }} {{ delivOrder.needed | time }}
          v-chip.ml-2(small, dark, color="secondary", v-if="hasAlcohol") Alcohol
          v-spacer
          .subtitle-2(v-if="distance") {{ distance }}
        #map(ref="map")
    v-card-text.px-0
      DriverTable(
        @select="selectDriver",
        :selected="selected",
        :grouped_drivers="grouped_drivers",
        :delivOrder="delivOrder"
      )
    v-card-actions
      v-col
        DriverSummary(:driverId="selected")
        v-btn(
          color="secondary",
          :disabled="!selected",
          @click="assign()",
          block
        ) Assign
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import moment from "moment";
import DriverTable from "./DriverTable";
import DriverSummary from "./DriverSummary";
import BizUtils from "@/utils/biz.js";

export default {
  components: { DriverTable, DriverSummary },
  data() {
    return {
      dialog: false,
      selected: null,
      delivOrder: null,
      driverMarker: null,
    };
  },
  computed: {
    ...mapGetters(["drivers", "driverSchedules", "deliv"]),
    current_driver() {
      if (
        this.delivOrder &&
        this.delivOrder.driver &&
        this.delivOrder.driver.id
      ) {
        return _.find(this.drivers, (o) => o._id == this.delivOrder.driver.id);
      }
      return null;
    },
    grouped_drivers() {
      if (!this.delivOrder) return [];
      const needed = moment(this.delivOrder.needed);
      const minutes =
        needed.hours() * 60 + Math.floor(needed.minutes() / 30) * 30;
      const schedules = _.filter(this.driverSchedules, (o) => {
        if (!o.status) return false;
        const block = _.find(o.blocks, (b) => b.mm == minutes);
        if (block) return block.status;
        else return false;
      });
      const users = _.pluck(schedules, "user");
      let working_group = [];
      let unknown_group = [];
      _.each(this.drivers, (o) => {
        if (users.indexOf(o.user) != -1) working_group.push(o);
        else unknown_group.push(o);
      });
      return {
        working_group: _.sortBy(working_group, "name"),
        unknown_group: _.sortBy(unknown_group, "name"),
      };
    },
    distance() {
      if (
        !this.delivOrder ||
        !this.delivOrder.seller ||
        !this.delivOrder.seller.address ||
        !this.delivOrder.seller.address.geometry ||
        !this.delivOrder.customer ||
        !this.delivOrder.customer.address ||
        !this.delivOrder.customer.address.geometry
      )
        return 0;
      let unit = "mi.";
      if (this.delivOrder.seller.address.country == "CA") unit = "km";
      const result = BizUtils.geoDistance2(
        this.delivOrder.customer.address.geometry,
        this.delivOrder.seller.address.geometry,
        unit
      );
      return result.value.toFixed(1) + " " + result.unit;
    },
    customer_location() {
      return (
        this.delivOrder.customer &&
        this.delivOrder.customer.address &&
        this.delivOrder.customer.address.geometry
      );
    },
    hasAlcohol() {
      const found = _.find(this.delivOrder.summary, (o) => {
        return o.classification == 3;
      });
      return found && found.amount > 0;
    },
  },
  methods: {
    ...mapActions(["updateDelivOrder", "setDriverSchedules", "setDrivers"]),
    selectDriver(driver) {
      this.selected = driver._id;
      this.setDriverMaker(driver.last_location);
    },
    setDriverMaker(location) {
      if (this.driverMarker) {
        // reset marker
        this.driverMarker.setMap(null);
        // if not recent location, skip
        let last30 = Date.now() - 30 * 60 * 1000;
        if (!location || location.time < last30) return;
      }
      // eslint-disable-next-line no-undef
      this.driverMarker = new google.maps.Marker({
        position: location,
        // label: "D",
        icon: {
          url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
        },
        clickable: false,
      });
      this.driverMarker.setMap(this.map);
      // eslint-disable-next-line no-undef
      // this.driverMarker.setAnimation(google.maps.Animation.BOUNCE);
    },
    setCurrentDriver() {
      if (!this.current_driver || !this.current_driver.last_location) return;
      const location = this.current_driver.last_location;
      let last30 = Date.now() - 30 * 60 * 1000;
      if (!location || location.time < last30) return;
      const icon = {
        url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
      };
      this.addMarker(location, "", icon);
    },
    async assign() {
      if (!this.delivOrder || !this.selected) {
        alert("Please select first");
        return;
      }
      if (
        this.delivOrder.driver &&
        this.delivOrder.driver.id == this.selected
      ) {
        alert("Already assigned to " + this.delivOrder.driver.name);
        return;
      }
      const data = {
        delivOrderId: this.delivOrder._id,
        driverId: this.selected,
      };
      try {
        const res = await this.axios.post("/delivOrders/assignDriver", data);
        this.updateDelivOrder(res.data);
        this.selected = null;
        this.dialog = false;
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
    },
    load(data) {
      if (!data) return;
      this.selected = null;
      this.delivOrder = data;
      this.dialog = true;
      const initMap = this.initMap;
      setTimeout(() => {
        initMap();
      }, 100);
      this.loadSchedules();
      this.loadDrivers();
    },
    async loadSchedules() {
      const date = moment().format("YYYY-MM-DD");
      const userIds = _.pluck(this.drivers, "user");
      const params = {
        criteria: {
          user: { $in: userIds },
          date: date,
        },
      };
      const res = await this.axios.post("/driver/schedules/list", params);
      this.setDriverSchedules(res.data);
    },
    async initMap() {
      if (!this.delivOrder) return;
      var center;
      if (
        this.delivOrder.seller &&
        this.delivOrder.seller.address &&
        this.delivOrder.seller.address.geometry
      ) {
        center = this.delivOrder.seller.address.geometry;
      } else {
        const options = {
          criteria: { _id: this.delivOrder.biz },
          select: "address",
        };
        const res = await this.axios.post("/bizs/retrieve", options);
        const biz = res.data;
        center = biz.address.geometry;
      }
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: center,
        zoom: 11,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false,
      });
      this.addMarker(center, "A");
      this.addMarker(this.customer_location, "B");
      this.setCurrentDriver();
    },
    addMarker(position, label, icon) {
      if (!position) return;
      // eslint-disable-next-line no-undef
      new google.maps.Marker({
        position: position,
        label: label,
        icon: icon,
        map: this.map,
        clickable: false,
      });
    },
    async loadDrivers() {
      if (!this.deliv || !this.deliv._id) return;
      const params = {
        criteria: { deliv: this.deliv._id, status: { $ne: false } },
      };
      const res = await this.axios.post("/driver/accounts/list", params);
      this.setDrivers(res.data);
    },
  },
  mounted() {
    EventBus.$on("assign-driver", this.load);
  },
  destroyed() {
    EventBus.$off("assign-driver");
  },
};
</script>

<style scoped>
#map {
  height: 180px;
  background: gray;
  margin-top: 6px;
  margin-bottom: 6px;
}
</style>