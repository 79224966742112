<template lang="pug">
.my-3
  v-chip.mr-3(
    v-for="(city, index) in cities",
    :key="index",
    text-color="white",
    color="green"
  ) {{ city.name }}
    v-avatar(right, color="green darken-4") {{ city.count }}
</template>

<script>
import _ from "underscore";

export default {
  props: ["bizs"],
  computed: {
    cities() {
      return _.chain(this.bizs)
        .groupBy((o) => {
          if (o && o.address && o.address.city && o.address.state)
            return o.address.city + ", " + o.address.state;
          else return "N/A";
        })
        .map((o, key) => {
          return {
            name: key,
            count: o.length,
          };
        })
        .sortBy((o) => -o.count)
        .value();
    },
  },
};
</script>
