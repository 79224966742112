<template lang="pug">
v-card
  v-card-text
    v-layout(align-center)
      .caption.sl-secondary-text Bank Accounts
      v-spacer
      MonthSelector(@select="load")
    v-divider.mt-2
  List(:payouts="payouts")
  .pa-3.caption.sl-secondary-text
    p We calculate the number of deliveries every Friday evening and transfer the fund to your account. You should expect a deposit of the fund on the coming Monday. There could be a delay when Monday is a holiday.
    p Transfer amount = #Selflane * $1 - #Other * $0.1 - (optional fee)
    p We deduct $3 from the balance for the monthly fee and deposit fee that is charged by Stripe. Other than that, there is no transaction fee nor other format fees to you.
</template>

<script>
import MonthSelector from "@/components/basic/MonthSelector";
import List from "./List";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { MonthSelector, List },
  data() {
    return {
      range: {},
      payouts: [],
    };
  },
  computed: {
    ...mapGetters(["deliv"]),
  },
  methods: {
    load(data) {
      if (data) this.range = data.range;
      if (!this.range || !this.range.begin) return;
      if (!this.deliv) return;

      const options = {
        criteria: {
          deliv: this.deliv._id,
          created: { $gt: this.range.begin, $lte: this.range.end },
        },
      };
      this.axios.post("/delivery/payouts/list", options).then((response) => {
        this.payouts = response.data;
      });
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-deliv", this.load);
  },
  destroyed() {
    EventBus.$off("switch-deliv");
  },
};
</script>
