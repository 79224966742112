<template lang="pug">
v-dialog(v-model="dialog", width="300")
  v-card(v-if="driver")
    v-toolbar(flat, dense)
      .subtitle-2 Edit Driver
    v-card-text
      v-form(@submit.prevent="save")
        v-text-field(
          v-model="name",
          label="Name",
          :error-messages="nameErrors",
          @blur="$v.name.$touch()"
        )
        v-text-field(
          v-model="phone",
          v-mask="\"(###) ###-####\"",
          label="Phone",
          :error-messages="phoneErrors",
          @blur="$v.phone.$touch()"
        )
        .mb-10
          .body-2 Zones
          v-checkbox(
            v-model="zones",
            :label="item",
            :value="item",
            v-for="(item, index) in zone_items",
            :key="index",
            color="secondary",
            hide-details
          )
        v-btn(block, color="secondary", type="submit", :disabled="$v.$invalid") Save
      v-divider.my-5
      v-btn.my-5(
        block,
        color="error",
        v-if="driver.status != false",
        @click="deactivate()"
      ) Deactivate
      v-btn.my-5(block, color="secondary", v-else, @click="activate()") Activate
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  name: "EditDriver",
  mixins: [validationMixin],
  validations: {
    name: { required },
    phone: { required },
  },
  data() {
    return {
      driver: null,
      dialog: false,
      name: "",
      phone: "",
      zones: [],
    };
  },
  methods: {
    ...mapActions(["updateDriver"]),
    open(data) {
      if (!data) return;
      this.driver = data;
      this.name = this.driver.name;
      this.phone = this.driver.phone;
      this.zones = _.filter(this.driver.zones, (zone) => {
        const found = _.find(this.zone_items, (o) => o == zone);
        return found != null;
      });
      this.dialog = true;
    },
    save() {
      if (!this.driver) return;
      const options = {
        criteria: { _id: this.driver._id },
        action: {
          $set: {
            name: this.name.trim(),
            phone: this.phone.replace(/[^\d]/g, ""),
            zones: this.zones,
          },
        },
      };
      this.update(options);
    },
    deactivate() {
      if (!this.driver) return;
      const options = {
        criteria: { _id: this.driver._id },
        action: { $set: { status: false } },
      };
      this.update(options);
    },
    activate() {
      if (!this.driver) return;
      const options = {
        criteria: { _id: this.driver._id },
        action: { $set: { status: true } },
      };
      this.update(options);
    },
    async update(options) {
      const res = await this.axios.post("/driver/accounts/update", options);
      this.updateDriver(res.data);
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters(["deliv"]),
    zone_items() {
      return this.deliv.zones;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push("Phone is required");
      return errors;
    },
  },
  mounted() {
    EventBus.$on("edit-driver", this.open);
  },
  destroyed() {
    EventBus.$off("edit-driver");
  },
};
</script>
