<template lang="pug">
v-dialog(v-model="dialog", width="600", scrollable)
  v-card(v-if="items")
    v-simple-table(dense)
      thead
        tr
          th Driver
          th Restaurant
          th.text-right Needed
          th.text-right Delivered
      tbody
        tr(v-for="(item, index) in items", :key="index + 'selected'")
          td {{ item.driver.name }}
          td {{ item.bizName }}
          td.text-right {{ item.needed | time }}
          td.text-right {{ item.deliverTime | time }}
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      dialog: false,
      items: [],
    };
  },
  methods: {
    open(orders) {
      this.dialog = true;
      this.items = orders;
    },
  },
  mounted() {
    EventBus.$on("show-order-byscore", this.open);
  },
  destroyed() {
    EventBus.$off("show-order-byscore");
  },
};
</script>
