<template lang="pug">
v-card.my-3(outlined)
  v-card-text
    .d-flex.flex-row.align-center.justify-space-between
      .caption.sl-secondary-text Basic
      Edit
    v-divider.mt-2
  v-list(dense, v-if="deliv")
    v-list-item
      v-list-item-content
        v-list-item-title Phone
      v-list-item-content.align-end
        v-list-item-subtitle.text-right {{ deliv.phone | phone }}
    v-list-item
      v-list-item-content
        v-list-item-title Email
      v-list-item-content.align-end
        v-list-item-subtitle.text-right {{ deliv.email }}
    v-list-item
      v-list-item-content
        v-list-item-title CC Recipients
      v-list-item-content.align-end
        v-list-item-subtitle.text-right {{ deliv.carboncopy }}
    v-list-item
      v-list-item-content
        v-list-item-title Serving States
      v-list-item-content.align-end
        v-list-item-subtitle.text-right {{ states }}
</template>

<script>
import { mapGetters } from "vuex";
import Edit from "./Edit";

export default {
  components: { Edit },
  computed: {
    ...mapGetters(["deliv"]),
    states() {
      if (!this.deliv || !this.deliv.states) return "";
      return this.deliv.states.join(", ");
    },
  },
};
</script>
