import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.delivOrder)?_c(VCard,[_c(VCardTitle,{staticClass:"pa-0"},[_c(VCol,[_c('div',{staticClass:"d-flex flex-row subtitle-2 justify-space-between align-center flex-nowrap pb-2"},[_c('div',{staticClass:"subtitle-2 text-truncate pr-3"},[_vm._v(_vm._s(_vm.delivOrder.bizName))]),_c(VSpacer),_c(VBtn,{staticClass:"text-capitalize",attrs:{"small":"","rounded":"","outlined":"","color":"success"},on:{"click":function($event){return _vm.loadSchedules()}}},[_vm._v("Schedules"),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-sync")])],1)],1),_c('div',{staticClass:"d-flex flex-row subtitle-2 justify-space-between align-center flex-nowrap"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.delivOrder.zone)+" "+_vm._s(_vm._f("time")(_vm.delivOrder.needed)))]),(_vm.hasAlcohol)?_c(VChip,{staticClass:"ml-2",attrs:{"small":"","dark":"","color":"secondary"}},[_vm._v("Alcohol")]):_vm._e(),_c(VSpacer),(_vm.distance)?_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.distance))]):_vm._e()],1),_c('div',{ref:"map",attrs:{"id":"map"}})])],1),_c(VCardText,{staticClass:"px-0"},[_c('DriverTable',{attrs:{"selected":_vm.selected,"grouped_drivers":_vm.grouped_drivers,"delivOrder":_vm.delivOrder},on:{"select":_vm.selectDriver}})],1),_c(VCardActions,[_c(VCol,[_c('DriverSummary',{attrs:{"driverId":_vm.selected}}),_c(VBtn,{attrs:{"color":"secondary","disabled":!_vm.selected,"block":""},on:{"click":function($event){return _vm.assign()}}},[_vm._v("Assign")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }