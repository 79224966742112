<template lang="pug">
v-dialog(v-model="dialog", width="300")
  v-card
    v-list(dense)
      v-list-item(v-for="(driver, index) in filteredDrivers", :key="index")
        v-list-item-content
          v-list-item-title {{ driver.name }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      users: [],
    };
  },
  computed: {
    ...mapGetters(["drivers"]),
    filteredDrivers() {
      return _.chain(this.drivers)
        .filter((o) => this.users.indexOf(o.user) != -1)
        .sortBy("name")
        .value();
    },
  },
  methods: {
    open(data) {
      this.users = data;
      this.dialog = true;
    },
  },
  mounted() {
    EventBus.$on("show-driver-schedule-block", this.open);
  },
  destroyed() {
    EventBus.$off("show-driver-schedule-block");
  },
};
</script>
