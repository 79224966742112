import _ from "underscore";

const state = {
  driverSchedules: []
};

const getters = {
  driverSchedules: state => state.driverSchedules
};

const actions = {
  setDriverSchedules: ({
    commit
  }, data) => {
    commit("setDriverSchedules", data);
  },
  updateDriverSchedule: ({
    commit
  }, data) => {
    commit("updateDriverSchedule", data);
  }
};

const mutations = {
  setDriverSchedules(state, data) {
    state.driverSchedules = data;
  },
  /**
   * If schedule exists, replace
   * Otherwise, push
   */
  updateDriverSchedule(state, data) {
    const found = _.find(state.driverSchedules, o => o._id == data._id)
    if (found) {
      state.driverSchedules = _.map(state.driverSchedules, o => {
        if (o._id === data._id) return data;
        return o;
      });
    } else {
      state.driverSchedules.push(data)
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};