import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"my-3",attrs:{"outlined":""}},[_c(VCardText,[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c('div',{staticClass:"caption sl-secondary-text"},[_vm._v("Address")]),_c(VBtn,{staticClass:"text-capitalize",attrs:{"outlined":"","small":"","depressed":"","color":"grey darken-2"},on:{"click":function($event){return _vm.open()}}},[_vm._v("Edit"),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-pencil")])],1)],1),_c(VDivider,{staticClass:"mt-2"})],1),(_vm.deliv)?_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("address")(_vm.deliv.address)))])],1)],1)],1):_vm._e(),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('AddressForm',{attrs:{"address":_vm.address},on:{"save":_vm.save}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }