<template lang="pug">
.d-flex.flex-row.align-center.sticky
  .sl-title Orders
  v-spacer
  Buttons
  Dispatch
</template>

<script>
import Dispatch from "./Dispatch/index";
import Buttons from "./Buttons";

export default {
  components: { Dispatch, Buttons },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 4;
  background: #F0F2F5;
  border-bottom: 1px solid #E7E7E7;
  margin-bottom: 12px
}
</style>