/// can access in creating an account /// or update an account that is not yet verified

<template lang="pug">
div
  v-form(@submit.prevent="submit")
    v-row(dense, align="center")
      v-col(cols="3") Name
      v-col
        v-text-field(label="First Name", v-model="person.first_name")
      v-col
        v-text-field(label="Last Name", v-model="person.last_name")
    v-row(dense, align="center")
      v-col(cols="3") Contact
      v-col
        v-text-field(
          label="Phone",
          v-model="person.phone",
          v-mask="\"(###) ###-####\""
        )
      v-col
        v-text-field(label="Email", v-model="person.email")
    v-row(dense, align="center")
      v-col(cols="3") Birthday
      v-col
        v-select(
          dense,
          label="Month",
          v-model="person.dob_month",
          :items="months"
        )
      v-col
        v-select(dense, label="Day", v-model="person.dob_day", :items="days")
      v-col
        v-select(
          dense,
          label="Year",
          v-model="person.dob_year",
          :items="years"
        )
    v-row(dense, align="center")
      v-col(cols="3") ID
      v-col
        v-text-field(:label="id_number_label", v-model="person.id_number")
    v-row(dense)
      v-col(cols="3") Address
      v-col
        v-row(dense)
          v-col(cols="9")
            v-text-field(
              label="Street",
              v-model="address.line1",
              placeholder="",
              ref="autocomplete",
              @keydown.enter.prevent=""
            )
          v-col(cols="3")
            v-text-field(label="Suite, Room", v-model="address.line2")
        v-row(dense)
          v-col
            v-select(
              label="Country",
              name="country",
              v-model="address.country",
              :items="countriesStates",
              item-text="name",
              item-value="abbreviation"
            )
          v-col
            v-select(
              label="State",
              name="state",
              v-model="address.state",
              :items="stateItems",
              item-text="name",
              item-value="abbreviation"
            )
        v-row(dense)
          v-col
            v-text-field(label="City", v-model="address.city")
          v-col
            v-text-field(label="Zipcode", v-model="address.postal_code")
    v-list(dense)
      v-list-item
        v-list-item-action
          v-checkbox(v-model="agreed", color="secondary")
        v-list-item-content(@click.prevent="agreed = !agreed")
          StripeToS
    v-btn(
      block,
      type="submit",
      color="secondary",
      :loading="loading",
      :disabled="!agreed"
    ) save
    .red--text {{ errors }}
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, integer } from "vuelidate/lib/validators";
import StripeToS from "./StripeToS";
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";

export default {
  props: ["dialog", "countriesStates"],
  name: "CreateIndividualAccount",
  components: { StripeToS },
  mixins: [validationMixin],
  validations: {
    person: {
      first_name: { required },
      last_name: { required },
      dob_year: { required, integer },
      dob_month: { required, integer },
      dob_day: { required, integer },
      phone: { required },
      email: { required },
    },
  },
  data: () => ({
    address: {
      line1: "",
      line2: "",
      postal_code: "",
      city: "",
      state: "",
      country: "US",
    },
    person: {
      first_name: "",
      last_name: "",
      dob_year: null,
      dob_month: null,
      dob_day: null,
      id_number: "",
      phone: "",
      email: "",
    },
    agreed: false,
    loading: false,
    errors: null,
    years: _.range(2010, 1920, -1),
    months: _.range(1, 13),
    days: _.range(1, 32),
  }),
  watch: {
    dialog(val) {
      if (val) this.open();
    },
  },
  methods: {
    ...mapActions(["setStripeAccount"]),
    open() {
      if (this.stripeAccount && this.stripeAccount.individual) {
        this.person.first_name = this.stripeAccount.individual.first_name;
        this.person.last_name = this.stripeAccount.individual.last_name;
        this.person.dob_year = this.stripeAccount.individual.dob.year;
        this.person.dob_month = this.stripeAccount.individual.dob.month;
        this.person.dob_day = this.stripeAccount.individual.dob.day;
        this.address = this.stripeAccount.individual.address;
      }
    },
    validate() {
      if (!this.address || !this.address.country || !this.address.line1 || !this.address.city) return "Invalid Address";
      if (!this.person.first_name) return "Person first name is required";
      if (!this.person.last_name) return "Person last name is required";
      if (!this.person.dob_year || !this.person.dob_month || !this.person.dob_day) return "Person birthday is required";
      if (!this.person.phone) return "Person phone is required";
      if (!this.person.email) return "Person email is required";
      if (!this.person.id_number) return this.id_number_label + " is required";
      return null;
    },
    submit() {
      this.errors = null;
      this.errors = this.validate();
      if (this.errors) return;
      this.loading = true;
      const stripeData = {
        business_type: "individual",
        individual: {
          address: this.address,
          dob: {
            year: this.person.dob_year,
            month: this.person.dob_month,
            day: this.person.dob_day,
          },
          first_name: this.person.first_name,
          last_name: this.person.last_name,
          id_number: this.person.id_number,
          email: this.person.email,
          phone: this.person.phone,
        },
      };
      if (this.stripeAccount) {
        this.update(stripeData);
      } else {
        this.create(stripeData);
      }
    },
    async create(data) {
      if (!this.deliv) return;
      const options = {
        delivId: this.deliv._id,
        stripeData: data,
      };
      try {
        const res = await this.axios.post("/stripe/accounts/create", options);
        this.setStripeAccount(res.data);
        this.$emit("done");
      } catch (err) {
        if (err.response) this.errors = err.response.data;
      }
      this.loading = false;
    },
    update(data) {
      if (!this.stripeAccount) return;
      const options = {
        accountId: this.stripeAccount.id,
        stripeData: data,
      };
      this.axios
        .post("/stripe/accounts/update", options)
        .then(response => {
          this.loading = false;
          this.setStripeAccount(response.data);
          this.$emit("done");
        })
        .catch(err => {
          this.loading = false;
          if (err.response) this.errors = err.response.data;
        });
    },
    setWithGooglePlace(place) {
      // address components
      const street_number_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "street_number") != undefined;
      });
      const route_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "route") != undefined;
      });
      const locality_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "locality") != undefined;
      });
      const administrative_area_level_1_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "administrative_area_level_1") != undefined;
      });
      const country_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "country") != undefined;
      });
      const postal_code_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "postal_code") != undefined;
      });
      this.address.line1 = street_number_component.short_name + " " + route_component.short_name;
      this.address.city = locality_component.short_name;
      this.address.state = administrative_area_level_1_component.short_name;
      this.address.country = country_component.short_name;
      this.address.postal_code = postal_code_component.short_name;
    },
  },
  computed: {
    ...mapGetters(["stripeAccount", "deliv"]),
    stateItems() {
      if (!this.countriesStates || !this.address) return [];
      const country = _.find(this.countriesStates, o => o.abbreviation == this.address.country);
      if (!country) return [];
      return country.states;
    },
    id_number_label() {
      if (this.address.country == "CA") return "Social Insurance Number";
      else return "Social Security Number";
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete.$refs.input
      // { types: ["geocode"] }
    );
    this.autocomplete.setComponentRestrictions({ country: ["us", "ca"] });
    this.autocomplete.setFields(["address_component", "geometry", "place_id"]);
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();
      this.setWithGooglePlace(place);
    });
  },
};
</script>
