import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import bizs from './modules/bizs'
import delivOrders from './modules/delivOrders'
import deliv from './modules/deliv'

import persons from './modules/Accounts/persons'

import drivers from './modules/Driver/drivers'
import driverPayouts from './modules/Driver/payouts'
import driverSchedules from './modules/Driver/schedules'

import stripeAccount from './modules/Stripe/account'
import stripeBanks from './modules/Stripe/banks'
import stripePersons from './modules/Stripe/persons'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    bizs,
    delivOrders,
    deliv,
    drivers,
    driverPayouts,
    driverSchedules,
    persons,
    stripeAccount,
    stripeBanks,
    stripePersons
  }
})
export default store