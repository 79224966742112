import Main from './Views/index.vue'
import Selflane from './Views/Selflane/index.vue'
import Others from './Views/Others/index.vue'

export default [{
  path: '/clients',
  component: Main,
  meta: {
    auth: true,
    role_owner: true
  },
  children: [{
    path: '',
    redirect: 'selflane'
  }, {
    path: 'selflane',
    component: Selflane
  }, {
    path: 'others',
    component: Others
  }]
}]