<template lang="pug">
.pa-3
  .d-flex.flex-row.align-center
    .sl-title Performance
    v-spacer
    WeekSelector(@select="load")
  v-divider.mb-2
  v-row(dense)
    v-col(cols="12", md="6")
      .sl-title Selflane Orders
      List(:list="selflaneList")
    v-col(cols="12", md="6")
      .sl-title Other Orders
      List(:list="nonSelflaneList")
</template>

<script>
import _ from "underscore";
import List from "./List";
import WeekSelector from "@/components/basic/WeekSelector";
import { mapGetters } from "vuex";

export default {
  components: { List, WeekSelector },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    ...mapGetters(["deliv"]),
    selflaneList() {
      return _.reject(this.list, (o) => o.type == 1);
    },
    nonSelflaneList() {
      return _.filter(this.list, (o) => o.type == 1);
    },
  },
  methods: {
    load(range) {
      if (!this.deliv || !this.deliv._id) return;
      const options = {
        criteria: {
          deliv: this.deliv._id,
          needed: { $gte: range.begin, $lte: range.end },
          status: { $gte: 0 },
        },
        select: "fee tip reward needed driver biz bizName",
      };
      this.axios.post("/delivOrders/listOrders", options).then((response) => {
        if (response.data) this.list = response.data;
      });
    },
  },
};
</script>
