<template lang="pug">
div(v-if="score")
  v-card.my-5(outlined, v-if="items")
    v-toolbar(flat, dense)
      .body-2 On-time Score {{ score | number(0) }} / 100
    v-divider
    v-simple-table
      tbody
        tr
          td(
            v-for="(item, index) in items",
            :key="index",
            @click="show(item.score)",
            :class="{ 'red--text': item.score < 3, 'green--text': item.score > 4 }",
            align="center",
            role="button"
          )
            div {{ item.score | scoreName }}
            div {{ item.count }}
  Dialog
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Dialog from "./Dialog";
import { EventBus } from "../../../event-bus";

export default {
  components: { Dialog },
  computed: {
    ...mapGetters(["delivOrders"]),
    items() {
      return _.chain(this.delivOrders)
        .filter((o) => o.ontime_score != null && o.type == 0)
        .groupBy("ontime_score")
        .map((o, k) => {
          return { score: k, count: o.length };
        })
        .value();
    },
    score() {
      if (!this.delivOrders || !this.delivOrders.length) return 0;
      const qualified = _.filter(this.delivOrders, (o) => {
        return o.ontime_score != null && o.type == 0;
      });
      if (!qualified.length) return 0;
      const total = _.reduce(
        qualified,
        (memo, item) => {
          return memo + item.ontime_score;
        },
        0
      );
      const count = qualified.length;
      return (total / count) * 20;
    },
  },
  filters: {
    scoreName(input) {
      const score = parseInt(input);
      switch (score) {
        case 5:
          return "Great";
        case 4:
          return "Good";
        case 3:
          return "Late";
        case 2:
          return "Very Late";
        case 1:
          return "Super Late";
        case 0:
          return "Disaster";
        default:
          return "Unknown";
      }
    },
  },
  methods: {
    show(score) {
      this.dialog = true;
      let items = _.filter(this.delivOrders, (o) => o.ontime_score == score);
      items = _.sortBy(items, "needed").reverse();
      EventBus.$emit("show-order-byscore", items);
    },
  },
};
</script>
