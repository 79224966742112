<template lang="pug">
v-dialog(v-model="dialog", max-width="800", scrollable)
  v-card(v-if="delivOrder")
    v-toolbar(dense, flat)
      .subtitle-2 Redeliver
      v-spacer
      .subtitle-2.text-truncate {{ delivOrder.bizName }}
    v-card-text
      v-text-field(
        label="Reason",
        v-model="reason",
        counter,
        persistent-hint,
        :hint="reason_hint"
      )
      Customer.my-3(:delivOrder="delivOrder")
      v-simple-table(v-if="order")
        tbody
          tr(v-for="(dish, index) in order.dishes", :key="index + 'dish'")
            td
              .subtitle-2 {{ dish.name }}
                span.ml-4.red--text(v-if="dish.pctOff > 0") {{ dish.pctOff }}% off
              .green--text {{ dish.modifiers | modifiers }}
            td.text-right &#215;{{ dish.quantity }}
          tr
            td Total
            td.text-right {{ order.payment.total | currency }}
    v-card-actions
      v-btn(
        color="secondary",
        @click="submit()",
        block,
        :loading="loading",
        :disabled="!valid"
      ) Create Redelivery
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Customer from "./../List/Cell/Customer";

export default {
  components: { Customer },
  data() {
    return {
      dialog: false,
      reason: "",
      delivOrder: null,
      order: null,
      loading: false,
      reason_hint:
        "e.g., manager (Name) called to redeliver for missing (Chicken Sandwich). Minimum 10 letters.",
    };
  },
  computed: {
    valid() {
      return this.reason && this.reason.length > 10;
    },
  },
  methods: {
    ...mapActions(["addDelivOrder"]),
    load(data) {
      if (!data) return;
      this.delivOrder = data;
      this.reason = "";
      this.dialog = true;
      this.loadOrder(data.order);
    },
    async loadOrder(orderId) {
      if (!orderId) return;
      const params = { criteria: { _id: orderId } };
      const res = await this.axios.post("/orders/retrieve", params);
      this.order = res.data;
    },
    async submit() {
      if (!this.delivOrder) return;
      this.loading = true;
      const params = {
        orderId: this.delivOrder.order,
        reason: this.reason,
      };
      try {
        const res = await this.axios.post("/orders/delivery/redeliver", params);
        this.addDelivOrder(res.data);
        this.dialog = false;
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
      this.loading = false;
    },
  },
  mounted() {
    EventBus.$on("redeliver-order", this.load);
  },
  destroyed() {
    EventBus.$off("redeliver-order");
  },
};
</script>
