<template lang="pug">
v-container
  StartForm
  .body-2.my-10 We will schedule a phone interview with your company in 24 hours. Once we understand your company's ability to provide quality services to restaurants, we will start to share your company with all restaurants in your delivery regions.
</template>

<script>
import StartForm from "./StartForm";

export default {
  components: { StartForm },
};
</script>
