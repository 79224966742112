import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isComplete)?_c('div',{staticClass:"text-center mt-3"},[_c(VBtn,{staticClass:"text-capitalize",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.open()}}},[_vm._v(_vm._s(_vm.title))]),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VContainer,[(!_vm.stripeAccount)?_c(VRadioGroup,{attrs:{"row":""},model:{value:(_vm.business_type),callback:function ($$v) {_vm.business_type=$$v},expression:"business_type"}},[_c(VRadio,{attrs:{"label":"Tax as an Individual","value":"individual"}}),_c(VRadio,{attrs:{"label":"Tax as a Company","value":"company"}})],1):_vm._e(),(this.business_type=="individual")?_c('IndividualAccount',{attrs:{"dialog":_vm.dialog,"countriesStates":_vm.countriesStates},on:{"done":function($event){_vm.dialog=false}}}):_vm._e(),(this.business_type=="company")?_c('CompanyAccount',{attrs:{"countriesStates":_vm.countriesStates},on:{"done":function($event){_vm.dialog=false}}}):_vm._e(),_c('span',[_vm._v("We use these information to produce 1099 tax form")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }