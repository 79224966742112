<template lang="pug">
v-container
  v-card
    v-card-text
      .d-flex.flex-row.align-center.justify-space-between
        .caption.sl-secondary-text Users & Access
        CreatePerson
      v-divider.mt-2
    List
</template>

<script>
import List from "./List/index";
import CreatePerson from "./CreatePerson";

export default {
  components: { List, CreatePerson },
};
</script>
