<template lang="pug">
v-card
  v-toolbar(flat, dense)
    .subtitle-2 Non-Selflane Order
  v-card-text.pt-3
    v-select(v-model="bizId", :items="bizItems", label="Restaurant", dense)
    v-select(v-model="driverId", :items="driverItems", dense, label="Driver")
    v-row(dense)
      v-col(cols="6")
        v-text-field(v-model="fee", label="Base", prefix="$")
      v-col(cols="6")
        v-text-field(v-model="tip", label="Tip", prefix="$")
    v-text-field(label="Customer Name", v-model="customerName")
    v-text-field(
      label="Customer Phone",
      v-mask="phoneMask",
      v-model="customerPhone",
      mask="phone"
    )
    v-text-field(
      label="Customer Address",
      v-model="customerAddressStr",
      placeholder="",
      ref="autocomplete",
      @keydown.enter.prevent=""
    )
    v-text-field(label="Note", v-model="note")
    .caption $0.10 for each external dispatch
  v-card-actions
    v-btn.text-capitalize(
      color="secondary",
      block,
      @click="submit()",
      :loading="loading"
    ) Dispatch
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";
import moment from "moment";
import { mask } from "vue-the-mask";
import { EventBus } from "@/event-bus.js";

export default {
  props: ["bizs", "dialog"],
  directives: { mask },
  data() {
    return {
      phoneMask: "(###) ###-####",
      loading: false,
      bizId: "",
      driverId: "",
      fee: 3,
      tip: 0,
      customerName: "",
      customerPhone: "",
      customerAddressStr: "",
      customerAddress: {},
      note: "",
    };
  },
  computed: {
    ...mapGetters(["deliv", "drivers"]),
    driverItems() {
      if (!this.drivers) return [];
      return _.chain(this.drivers)
        .map((o) => {
          return {
            value: o._id,
            text: o.name,
          };
        })
        .sortBy("text")
        .value();
    },
    bizItems() {
      if (!this.bizs) return [];
      const data = _.chain(this.bizs)
        .reject((o) => !o || !o._id)
        .map((o) => {
          return {
            value: o._id,
            text: o.name + ", " + o.address.city,
          };
        })
        .sortBy("text")
        .value();
      return data;
    },
  },
  watch: {
    dialog(val, oval) {
      if (val != oval && val) {
        this.driverId = "";
        this.bizId = "";
        this.customerName = "";
        this.customerPhone = "";
        this.customerAddress = "";
        this.customerAddress = {};
        this.fee = 3;
        this.tip = 0;
        this.note = "";
        this.customerAddressStr = "";
      }
    },
  },
  methods: {
    ...mapActions(["addDelivOrder"]),
    create() {
      if (!this.deliv) return;
      this.driverId = "";
      this.bizId = "";
      this.customerName = "";
      this.customerPhone = "";
      this.customerAddress = "";
      this.customerAddress = {};
      this.fee = 3;
      this.tip = 0;
      this.note = "";
      this.customerAddressStr = "";
    },
    async submit() {
      if (!this.deliv) return;
      this.customerAddress.note = this.note;
      let delivOrder = {
        type: 1,
        deliv: this.deliv._id,
        biz: "",
        bizName: "",
        driver: {
          id: null,
          name: "",
          email: "",
          phone: "",
        },
        customer: {
          name: this.customerName,
          phone: this.customerPhone,
          address: this.customerAddress,
        },
        tip: this.tip,
        fee: this.fee,
        manfee: 1,
        surcharge: 0.1,
        note: "",
        status: 0,
        needed: moment().add(20, "minutes").unix() * 1000,
      };
      if (this.bizId) {
        const found = _.find(this.bizs, (o) => o._id == this.bizId);
        if (found) {
          delivOrder.bizName = found.name;
          delivOrder.biz = found._id;
          let phone = "";
          if (found.phones && found.phones.length) {
            phone = found.phones[0].number;
          }
          delivOrder.seller = {
            id: found._id,
            name: found.name,
            phone: phone,
            industry: found.industry,
            address: found.address,
          };
        }
      }
      if (!delivOrder.biz) {
        alert("Please select a restaurant first");
        return;
      }
      if (this.driverId) {
        const found = _.find(this.drivers, (o) => o._id == this.driverId);
        delivOrder.status = 0;
        if (found) {
          delivOrder.driver = {
            id: found._id,
            name: found.name,
            phone: found.phone,
            email: found.email,
          };
          delivOrder.status = 2;
        }
      }
      if (delivOrder.tip < 0 || delivOrder.tip > 100) {
        alert("Tip should be between 0 and 100");
        return;
      }
      this.loading = true;
      try {
        const res = await this.axios.post(
          "/delivOrders/createByDeliv",
          delivOrder
        );
        this.addDelivOrder(res.data);
        this.$emit("saved");
      } catch (err) {
        alert(err.response.data);
      }
      this.loading = false;
    },
    setWithGooglePlace(place) {
      // address components
      const street_number_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "street_number") != undefined;
      });
      const route_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "route") != undefined;
      });
      const locality_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "locality") != undefined;
      });
      const administrative_area_level_1_component = _.find(
        place.address_components,
        (o) => {
          return (
            _.find(o.types, (t) => t == "administrative_area_level_1") !=
            undefined
          );
        }
      );
      const country_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "country") != undefined;
      });
      const postal_code_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "postal_code") != undefined;
      });
      this.customerAddress.line1 =
        street_number_component.short_name + " " + route_component.short_name;
      this.customerAddress.city = locality_component.short_name;
      this.customerAddress.state =
        administrative_area_level_1_component.short_name;
      this.customerAddress.country = country_component.short_name;
      this.customerAddress.postal_code = postal_code_component.short_name;
      this.customerAddress.geometry = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete.$refs.input
      // { types: ["geocode"] }
    );
    this.autocomplete.setFields(["address_component", "geometry"]);
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();
      this.setWithGooglePlace(place);
    });
    EventBus.$on("create-manager-order", this.create);
  },
  destroyed() {
    EventBus.$off("create-manager-order");
  },
};
</script>
