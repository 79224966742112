<template lang="pug">
v-list-item(@click='show()') Show Orders
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "ShowPayoutOrders",
  props: ["payout"],
  methods: {
    ...mapActions(["updateDriverPayout"]),
    show() {
      EventBus.$emit("show-delivorders", this.payout.delivOrders);
    },
  },
};
</script>
