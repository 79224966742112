import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.delivOrder)?_c(VCard,[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"subtitle-2"},[_vm._v("Redeliver")]),_c(VSpacer),_c('div',{staticClass:"subtitle-2 text-truncate"},[_vm._v(_vm._s(_vm.delivOrder.bizName))])],1),_c(VCardText,[_c(VTextField,{attrs:{"label":"Reason","counter":"","persistent-hint":"","hint":_vm.reason_hint},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('Customer',{staticClass:"my-3",attrs:{"delivOrder":_vm.delivOrder}}),(_vm.order)?_c(VSimpleTable,[_c('tbody',[_vm._l((_vm.order.dishes),function(dish,index){return _c('tr',{key:index + 'dish'},[_c('td',[_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(dish.name)),(dish.pctOff > 0)?_c('span',{staticClass:"ml-4 red--text"},[_vm._v(_vm._s(dish.pctOff)+"% off")]):_vm._e()]),_c('div',{staticClass:"green--text"},[_vm._v(_vm._s(_vm._f("modifiers")(dish.modifiers)))])]),_c('td',{staticClass:"text-right"},[_vm._v("×"+_vm._s(dish.quantity))])])}),_c('tr',[_c('td',[_vm._v("Total")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.order.payment.total)))])])],2)]):_vm._e()],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"secondary","block":"","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Create Redelivery")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }