import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VList,{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.stripePersons),function(item,index){return _c(VListItem,{key:index},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))]),_c(VListItemSubtitle,{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.verification.status))])],1),_c(VListItemAction,[(_vm.stripePersons.length > 1)?_c('DeletePerson',{attrs:{"person":item}}):_vm._e()],1)],1)}),1),(!_vm.stripePersons || !_vm.stripePersons.length)?_c('div',{staticClass:"ma-3 text-center subtitle-2"},[_vm._v("Please Add a Company Owner")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }