<template lang="pug">
div
  v-row.ma-3(justify="center")
    v-btn-toggle(v-model="driverStatus", color="accent", dense)
      v-btn(value="active", small) Active
      v-btn(value="inactive", small) Inactive
  Table
  Edit
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Table from "./Table";
import Edit from "./Edit";

export default {
  components: { Table, Edit },
  data() {
    return {
      driverStatus: "active",
    };
  },
  computed: {
    ...mapGetters(["deliv", "drivers"]),
  },
  watch: {
    driverStatus(val, oval) {
      if (val != oval) {
        this.load();
      }
    },
  },
  methods: {
    ...mapActions(["setDrivers"]),
    async load() {
      if (!this.deliv || !this.deliv._id) return;
      let params;
      if (this.driverStatus == "active") {
        params = {
          criteria: { deliv: this.deliv._id, status: { $ne: false } },
        };
      } else {
        params = { criteria: { deliv: this.deliv._id, status: false } };
      }
      const res = await this.axios.post("/driver/accounts/list", params);
      this.setDrivers(res.data);
    },
  },
  mounted() {
    this.load();
  },
};
</script>
