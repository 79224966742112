<template lang="pug">
.pa-3
  .d-flex.flex-row.align-center.justify-space-between
    .sl-title Driver Payouts
    Load
  v-divider.mb-3
  List
</template>

<script>
import Load from "./Load";
import List from "./List/index";

export default {
  components: { Load, List },
};
</script>
