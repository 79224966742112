function addressStreetCity(address) {
  if (!address) return ''
  const lines = []
  if (address.line1) {
    lines.push(address.line1)
  }
  if (address.line2) {
    lines.push(address.line2)
  }
  const list = [lines.join(' ')]
  if (address.city) list.push(address.city)
  return list.join(', ')
}

export default addressStreetCity