<template lang="pug">
v-container
  .d-flex.flex-row.align-center.justify-space-between
    .sl-title Hire
    CreateDriver
  v-divider.mb-3
  List
</template>

<script>
import CreateDriver from "./CreateDriver";
import List from "./List/index";

export default {
  components: { CreateDriver, List },
};
</script>
