<template lang="pug">
v-card
  v-toolbar(flat, dense)
    .subtitle-2 Address Form
  v-card-text
    v-form(@submit.prevent="submit")
      v-row(dense)
        v-col(cols="9")
          v-text-field(
            label="Street",
            placeholder="",
            v-model="line1",
            :error-messages="line1Errors",
            required,
            @blur="$v.line1.$touch()",
            ref="autocomplete",
            @keydown.enter.prevent=""
          )
        v-col(cols="3")
          v-text-field(
            label="Apt, room",
            v-model="line2",
            :error-messages="line2Errors",
            @blur="$v.line2.$touch()"
          )
      v-row(dense, wrap)
        v-col(cols="6")
          v-select(
            label="Country",
            name="state",
            v-model="country",
            :items="countriesStates",
            item-text="name",
            item-value="abbreviation"
          )
        v-col(cols="6")
          v-select(
            label="State",
            name="state",
            v-model="state",
            :items="stateItems",
            item-text="name",
            item-value="abbreviation",
            :error-messages="stateErrors",
            @blur="$v.address.state.$touch()"
          )
        v-col(cols="6")
          v-text-field(
            label="City",
            v-model="city",
            :error-messages="cityErrors",
            @blur="$v.city.$touch()"
          )
        v-col(cols="6")
          v-text-field(label="Zipcode", v-model="postal_code")
      v-btn(
        block,
        :disabled="invalid",
        type="submit",
        color="secondary",
        :loading="loading"
      ) save
    .pl-2.red--text(v-for="(error, index) in errors", :key="index + 'index'") {{ error }}
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import MapUtils from "@/utils/map";
import _ from "underscore";

export default {
  name: "AddressForm",
  props: ["address"],
  mixins: [validationMixin],
  validations: {
    line1: {
      required,
      maxLength: maxLength(40),
    },
    line2: {
      maxLength: maxLength(15),
    },
    postal_code: {
      required,
      minLength: minLength(5),
    },
    city: {
      required,
    },
    state: {
      required,
    },
  },
  data() {
    return {
      line1: "",
      line2: "",
      postal_code: "",
      city: "",
      state: "",
      country: "US",
      countriesStates: [],
      loading: false,
      errors: [],
    };
  },
  watch: {
    address: {
      handler(val) {
        this.$v.$reset();
        if (!val) return;
        this.line1 = val.line1;
        this.line2 = val.line2;
        this.postal_code = val.postal_code;
        this.city = val.city;
        this.state = val.state;
        this.country = "US";
        this.errors = [];
      },
      deep: true,
    },
  },
  computed: {
    stateItems() {
      if (!this.countriesStates || !this.address) return [];
      const country = _.find(this.countriesStates, o => o.abbreviation == this.address.country);
      if (!country) return [];
      return country.states;
    },
    line1Errors() {
      const errors = [];
      if (!this.$v.line1.$dirty) return errors;
      !this.$v.line1.required && errors.push("Required");
      !this.$v.line1.maxLength && errors.push("Should be less than 40 charactors");
      return errors;
    },
    line2Errors() {
      const errors = [];
      if (!this.$v.line2.$dirty) return errors;
      !this.$v.line2.maxLength && errors.push("Should be less than 15 charactors");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("Required");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push("Required");
      return errors;
    },
    invalid() {
      return this.$v.$invalid;
    },
  },
  methods: {
    processAddress() {
      const data = {
        line1: this.line1.trim(),
        line2: this.line2,
        postal_code: this.postal_code,
        city: this.city.trim(),
        state: this.state,
        country: this.country,
      };
      return new Promise((resolve, reject) => {
        MapUtils.getGeoCode(data)
          .then(response => {
            if (response && response.data && response.data.results && response.data.results.length) {
              data.geometry = response.data.results[0].geometry.location;
              MapUtils.getTimeZone(data.geometry.lat, data.geometry.lng)
                .then(response => {
                  data.timezone = response.data.timeZoneId;
                  resolve(data);
                })
                .catch(() => {
                  this.errors = ["Can't find the correct timezone for the input address"];
                  reject(null);
                });
            } else {
              this.errors = ["Can't find the correct geo location for the input address"];
              reject(null);
            }
          })
          .catch(() => {
            this.errors = ["Can't find the correct geo location for the input address"];
            reject(null);
          });
      });
    },
    async submit() {
      this.loading = true;
      const address = await this.processAddress();
      this.$emit("save", address);
      this.loading = false;
    },
    setWithGooglePlace(place) {
      // address components
      const street_number_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "street_number") != undefined;
      });
      const route_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "route") != undefined;
      });
      const locality_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "locality") != undefined;
      });
      const administrative_area_level_1_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "administrative_area_level_1") != undefined;
      });
      const country_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "country") != undefined;
      });
      const postal_code_component = _.find(place.address_components, o => {
        return _.find(o.types, t => t == "postal_code") != undefined;
      });
      this.line1 = street_number_component.short_name + " " + route_component.short_name;
      this.city = locality_component.short_name;
      this.state = administrative_area_level_1_component.short_name;
      this.country = country_component.short_name;
      this.postal_code = postal_code_component.short_name;
    },
  },
  mounted() {
    MapUtils.loadCountriesStates().then(data => {
      this.countriesStates = data;
    });

    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete.$refs.input
      // { types: ["geocode"] }
    );
    this.autocomplete.setFields(["address_component", "geometry", "place_id"]);
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();
      this.setWithGooglePlace(place);
    });
  },
};
</script>
