<template lang="pug">
div
  Guest
  Companies
</template>

<script>
import Guest from "./Guest";
import Companies from "./Companies/index";

export default {
  components: { Guest, Companies },
};
</script>
