<template lang="pug">
div
  .d-flex.flex-row.justify-center.mb-2.sticky 
    v-btn-toggle(v-model="status", rounded, color="secondary")
      v-btn.text-capitalize(small, value="open") New {{ open_orders.length }}
      v-btn.text-capitalize(small, value="open_accepted")
        v-icon(small, left) mdi-account-check
        span {{ open_accepted_orders.length }}
      v-btn.text-capitalize(small, value="in-route")
        v-icon(small, left) mdi-car-arrow-right
        span {{ in_route_orders.length }}
      v-btn.text-capitalize(small, value="fulfilled")
        v-icon(small, left) mdi-check
        span {{ fulfilled_orders.length }}
  .text-center.mb-2
    v-chip(small, outlined) Selflane {{ selflaneCount }} &#149; Others {{ delivOrders.length - selflaneCount }}
  List(v-if="status == 'open'", :items="open_orders")
  List(v-if="status == 'open_accepted'", :items="open_accepted_orders")
  List(v-if="status == 'in-route'", :items="in_route_orders")
  List(v-if="status == 'fulfilled'", :items="fulfilled_orders")
  MapDialog
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import DaySelector from "@/components/basic/DaySelector";
import List from "./List";
import MapDialog from "./MapDialog/index";

export default {
  components: { DaySelector, List, MapDialog },
  data: () => ({
    selectedDate: new Date(),
    loading: false,
    status: "open",
  }),
  computed: {
    ...mapGetters(["delivOrders"]),
    /// no driver or driver not accepted
    open_orders() {
      return _.chain(this.delivOrders)
        .reject((o) => o.status < 0 || o.pickupTime)
        .reject((o) => o.driver && o.driver.accept > 0)
        .sortBy("needed")
        .value();
    },
    /// accepted by drivers
    open_accepted_orders() {
      return _.chain(this.delivOrders)
        .reject((o) => o.status < 0 || o.pickupTime)
        .filter((o) => o.driver && o.driver.accept > 0)
        .sortBy("needed")
        .value();
    },
    /// in route
    in_route_orders() {
      return _.chain(this.delivOrders)
        .reject((o) => o.status < 0)
        .filter((o) => o.pickupTime && !o.deliverTime)
        .sortBy("needed")
        .value();
    },
    fulfilled_orders() {
      return _.chain(this.delivOrders)
        .filter((o) => o.status == 4)
        .sortBy("needed")
        .value()
        .reverse();
    },
    selflaneCount() {
      return _.filter(this.delivOrders, (o) => o.type == 0).length;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 105px;
  z-index: 4;
  background: #F0F2F5;
  margin-bottom: 12px
}
</style>